@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
/*
Towson University Branding
TU Gold - FFBB00
TU Graphite - 3C3C3C
TU White - FFFFFF
TU Black - 151500
TU Glen Mist - DDDDDD

Athletics
TU Mascot Red - CC0033
TU Athletic Silver - C0C0C0
*/

:root {
  --w-main-minimum: 375px;
  --w-minimum: 540px;
  --w-maximum: 840px;
  --w-profile-minimum: 540px;
  --w-profile-maximum: 840px;
  --w-hubs-btn: 130px;
  --h-hubs-btn: 40px;
  --profile-header-height: 300px;

  --message-app-left-panel: 300px;

  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px){
  :root {
    --w-minimum: 240px;
    --w-maximum: 600px;
    --w-profile-minimum: 240px;
    --w-profile-maximum: 600px;
    --profile-header-height: 210px;
    display: flex;
    justify-content: center;
  }
}

* {
  /* font-family: Arial, Helvetica, sans-serif; */
  background-color: transparent;
  list-style: none;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Light Theme
  #006994 - 2px borders and buttons (either solid or when hovering)
  #94b1c9 - Input text (AND icons)
  #f6fcff - page background (layer 0)
  white - container background (layer 1)
  #dbe4ed - background color of the buttons and search bar (layer 3)
  #6F98B7 - Search bar text (Layer 4)
  */

/* Dark Theme
  #006994 - 2px borders and buttons (either solid or when hovering)
  #94b1c9 - Input text
  #1a1a1a - page background (layer 0)
  #333333 - container background (layer 1)
  #4d4d4d - background color of the buttons and search bar (layer 3)
  #f2f2f2 - Search bar text (Layer 4)
  */

/* body {
  --ocean-blue: #006994;
  --page-background: #ebf5fa;
  --primary-color: #006994;
  --primary-text-color: #006994e6;
  --primary-hover-color: #006994;
  --secondary-text-color: rgba(0, 105, 148, 0.6);
  --nav-text-color: #dbe4ed;
  --container-background-color: white;
  --like-button-active: #006994;
  --button-background-color: #dbe4ed;
  --button-text-color: #006994;
  margin: 0;
  background-color: var(--page-background);
} */

body {
  --action-btn-text: #DDDDDD;
  --action-btn-text-hover: #3c3c3c;
  --page-background: #f0f0f0;
  --towson-gold: #ffbb00;
  --text-over-button: #DDDDDD;
  --primary-color: #3c3c3c;
  --primary-text-color: #151500;
  --primary-hover-color: #ffbb00;
  --secondary-text-color: #3c3c3c;
  --nav-text-color: #DDDDDD;
  --like-button-active: #FFBB00;
  --button-background-color: #DDDDDD;
  --button-text-color: #3c3c3c;
  --stat-label: #8c8c8c;
  --stat-color: #3c3c3c;

  /* --container-background-color: white; */
  --level-one-background: #f0f0f0;
  --level-two-background: white;
  --level-three-background: #ddd;
  --level-four-background: #eee;

  /* NAV DRAWER */
  --nav-drawer-background: white;
  --nav-drawer-text: #3c3c3c;
  --nav-drawer-hover: #ffbb00;
  --nav-drawer-active: rgba(60, 60, 60, .5);
  --nav-drawer-active-text: #3c3c3c;
  --nav-drawer-border: rgba(221, 221, 221, 1);
  
  /* MESSAGE APP */
  --message-app-container: #3c3c3c;
  --message-app-panel-background: white;
  --message-app-primary-text: #151500;
  --message-app-secondary-text: #3c3c3c;
  --message-app-chat-bubble: #eee;
  --message-app-toolbar-labels: #ddd;
  --message-app-pop-up: #3c3c3c;

  margin: 0;
  background-color: var(--page-background);
}

#main-body {
  z-index: 0;
}

body.dark-theme {
  --action-btn-text: #DDDDDD;
  --towson-gold: #ffbb00;
  --primary-color: #585858;
  --primary-text-color: rgba(242, 242, 242, 0.8);
  --secondary-text-color: #f2f2f298;
  --nav-text-color: rgba(242, 242, 242, 0.8);
  --like-button-active: #949191;
  --primary-hover-color: #949191;
  --page-background: #1a1a1a;
  --button-background-color: #4d4d4d;
  --button-text-color: #dbe4ed;
  --stat-label: #777;
  --stat-color: var(--secondary-text-color);

  /* --container-background-color: #333333; */
  --level-one-background: #1a1a1a;
  --level-two-background: #333333;
  --level-three-background: #4a4a4a;
  --level-four-background: #5a5a5a;

    /* NAV DRAWER */
    --nav-drawer-background: #3c3c3c;
    --nav-drawer-text: rgba(221, 221, 221, .7);
    --nav-drawer-hover: #ffbb00;
    --nav-drawer-active: rgba(221, 221, 221, .5);
    --nav-drawer-active-text: #dddddd;
    --nav-drawer-border: rgba(221, 221, 221, 0.2);

  /* MESSAGE APP */
  --message-app-container: #2f2f2f;
  --message-app-panel-background: #3c3c3c;
  --message-app-primary-text: #eee;
  --message-app-secondary-text: #eee;
  --message-app-chat-bubble: #5b5b5b;
  --message-app-toolbar-labels: #ddd;
  --message-app-pop-up: #1a1a1a;

  margin: 0;
  background-color: var(--page-background);
}

#background-img {
  opacity: .15;
  padding: 0;
  margin: 0;
  background-size: contain;
  position: fixed;
  filter: blur(8px);
  top: 0px;
  left: 0px;
  z-index: -1000;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-80 {
  padding-top: 80px;
}

.container-border {
  --shadow-toggle: 0px 0px 5px rgba(26, 26, 26, 0.3);
  /* border: 2px solid var(--primary-color); */
  border-radius: 15px;
  box-shadow: var(--shadow-toggle);
}

.container-border-heavier {
  --shadow-toggle: 0px 0px 25px rgba(26, 26, 26, 0.5);
  border-radius: 25px;
  box-shadow: var(--shadow-toggle);
}

.container-border.dark-theme {
  --shadow-toggle: none;
  box-shadow: var(--shadow-toggle);
  border-radius: 25px;
}

.container-border-heavier.dark-theme {
  --shadow-toggle: none;
  box-shadow: var(--shadow-toggle);
}

.make-grid {
  display: grid;
}

.scrollbar-mch-thin {
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--level-two-background);
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

.scrollbar-mch-thin:hover {
  overflow-y: scroll;
}

.scrollbar-mch-thick {
  overflow: hidden;
  scrollbar-color: var(--primary-color) var(--level-two-background);
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

.scrollbar-mch-thick:hover {
  overflow-y: scroll;
}

::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
}

.scroll-hide {
  -ms-overflow-style: none ;  /*IE and Edge*/
  overflow: hidden;  /* Firefox */
}

/* ::-webkit-scrollbar { */
  /* display: none; */
/* } */

.disp-none {
  display: none;
}

.input-file-text {
  font-weight: normal;
  font-size: .8em;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-25 {
  border-radius: 25px;
}

.h1-header-text {
  font-size: 1.6em;
  font-weight: 600;
}

.bounded-textarea {
  font-size: .9em;
  color: var(--primary-text-color);
  padding: 10px 0px 10px 0px;
  margin-bottom: 0px;
  width: 100%;
  height: 60%;
  border: none;
  resize: none;
  overflow: none;
  outline: none;
  grid-column: 1 / end;
  grid-row: 3 / 4;
  opacity: .7;
}

.ul-inline {
  margin: 0;
  padding: 0;
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.ul-inline li:hover {
  cursor: pointer;
  opacity: .7;
}

.ul-no-padding {
  margin: 0;
  padding: 0;
}

.ul-no-padding li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 13px;
}

.ul-no-padding li:last-child {
  margin-bottom: 0;
}

.ul-no-padding li:hover {
  cursor: pointer;
  opacity: .7;
}

.background-img-contain {
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.clickable:hover {
  cursor: pointer !important;
  opacity: .8;
}

.clickable-po:hover { /*po = pointer only*/
  cursor: pointer;
}

.submit-btn {
  color: var(--primary-color) !important;
  background-color: var(--towson-gold) !important;
}

.hr-custom {
  width: 100%;
  border: 1px solid var(--level-three-background);
  opacity: .6;
  margin: 0;
}

.close-window {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.resource-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/********************************************************************/
/********************************************************************/
/******************************* PANELS *****************************/
/********************************************************************/
/********************************************************************/

#main-panel {
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: minmax(240px, 26.5%) max(840px) minmax(240px, 26.5%);
  gap: 1.5rem;
}

@media only screen and (max-width: 600px){
  #main-panel {
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 100vw;
    gap: 1rem;
  } 
}

/********************************************************************/
/***************************** LEFT PANEL ***************************/
/********************************************************************/

#left-panel {
  width: 300px;
  text-wrap: wrap;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-left: auto;
  overflow: hidden;
}

@media only screen and (max-width: 600px){
  #left-panel {
    width: 30px;
    text-wrap: wrap;
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    padding-left: 2px;
    margin-left: auto;
    overflow: hidden;
  } 
}

#suggested-oceans-container, #child-oceans-container {
  /* background-color: var(--text-over-button); */
  width: 300px;
  margin-right: auto;
  height: fit-content;
  padding: 20px 0, 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#suggested-oceans-container hr, #child-oceans-container hr {
  width: 80%;
  max-width: 280px;
  min-width: 100px;
  align-content: start;
  border: 1px solid var(--secondary-text-color);
  margin-left: 0;
  margin-bottom: 30px;
  opacity: .4;
}

#suggested-oceans-container p, #child-oceans-container p {
  width: 80%;
  max-width: 280px;
  min-width: 100px;
  color: var(--secondary-text-color);
  font-size: .8em;
  line-height: 1.2em;
  margin-right: auto;
  display: block;
}

#suggested-oceans-container h2, #child-oceans-container h2 {
  font-size: 1.1em;
  color: var(--primary-text-color);
  line-height: 0%;
  width: 100%;
  text-align: left;
  margin: 10px 0 25px 0;
  opacity: .8;
}

#suggested-oceans-listings-area {
  width: 100%;
  grid-template-columns: 90px 1fr;
  grid-template-rows: 1fr 1fr;
  color: var(--secondary-text-color);
}

#suggested-friends-listings-area {
  width: 100%;
  grid-template-columns: 55px 1fr;
  grid-template-rows: 1fr 1fr;
  color: var(--secondary-text-color);
}

#suggested-oceans-listings-area img, #child-oceans-listings-area img {
  width: 80px;
  height: 40px;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  grid-column: 1 / 2;
  grid-row: 1 / end;
  margin-top: 5px;
  object-fit: cover;
}

#suggested-friends-listings-area img {
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  grid-column: 1 / 2;
  grid-row: 1 / end;
  margin-top: 5px;
  margin-right: 0px;
  object-fit: cover;
}

#suggested-oceans-stats-title, #child-oceans-stats-title, #suggested-friends-stats-title {
  font-size: .8em;
  line-height: 0px;
  margin: 0;
  grid-column: 2 / end;
  grid-row: 1 / 2;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#suggested-friends-stats-title {
  font-size: .8em;
  line-height: 0px;
  margin: 0;
  grid-column: 2 / end;
  grid-row: 1 / 2;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#suggested-oceans-listings-area p, #suggested-friends-listings-area p, #child-oceans-listings-area p{
  line-height: 0px;
  font-size: .8em;
  margin-top: 5px;
  grid-column: 2 / end;
  grid-row: 2 / end;
}

#create-ocean-btn {
  font-size: .75em;
  font-weight: 500;
  color: var(--button-background-color);
  width: fit-content;
  height: 30px;
  border-radius: 20px;
  border: 0;
  background-color: var(--button-text-color);
  margin: 5px auto 10px 0;
  padding: 0 15px 0 15px;
  line-height: .9em;
  display: block;
}

/********************************************************************/
/**************************** CENTER PANEL **************************/
/********************************************************************/

#center-panel {
  grid-column: 2 / 3;
}


@media only screen and (max-width: 600px){
  #center-panel {
    padding-left: 10px;
    padding-right: 10px;
    grid-column: 1;
  } 
}

/********************************************************************/
/***************************** RIGHT PANEL **************************/
/********************************************************************/

#right-panel {
  width: 300px;
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
  justify-content: right;
  padding-right: 20px;
}

#child-oceans-container {
  width: 90%;
  margin-left: auto;
  height: fit-content;
  padding: 20px 0, 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#child-oceans-listings-area {
  width: 100%;
  grid-template-columns: 90px 1fr;
  grid-template-rows: 1fr 1fr;
  color: var(--secondary-text-color);
  margin-bottom: 5px;
}

/********************************************************************/
/********************************************************************/
/***************************** LOGIN PAGE ***************************/
/********************************************************************/
/********************************************************************/

#main-login-container {
  min-width: var(--w-main-minimum);
  width: fit-content;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  margin: 0;
  gap: 30px;
  margin-bottom: 10px;
}

#tiles-area {
  min-width: 400px;
  max-width: 50rem;
  width: fit-content;
  margin: 0 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: var(--button-background-color);
  padding: 0 0 0 10px;
}

#tile {
  background-color: transparent;
  width: 200px;
  height: 116px;
  border-radius: 25px;
  margin: 10px;
  float: left;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 30px 20px;
  position: relative;
  -moz-box-shadow: inset 0px 0px 15px #000000; 
  -webkit-box-shadow: inset 0px 0px 15px #000000; 
  box-shadow: inset 0px 0px 10px #000000;
  overflow: hidden;
}

#tiles-body-text-container {
  width: 200px;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  padding: 5px 0 15px 10px;
}

#tile h6 {
  background-color: rgba(0, 0, 0, .5);
  padding: 5px 10px 5px 10px;
  font-size: .65em;
  height: fit-content;
  grid-row: 1 / 2;
  margin: 0;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

#tile h3 {
  font-size: 1em;
  height: fit-content;
  grid-row: 2 / 3;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* background-color: rgba(0, 0, 0, .5); */
  /* margin-top: 9px;*/
  padding-right: 15px;
}

#tile p {
  font-size: .7em;
  height: fit-content;
  grid-row: 3 / end;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* background-color: rgba(0, 0, 0, .5); */
  padding: 0 15px 0 0;
  /*border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;*/
}

#tile img {
  max-width: 100%;
  height: fit-content;
  border-radius: 25px;
  position: absolute;
  z-index: -1;
  transform: scale(1);
  animation: pulse 9s infinite;
  /* top: 0;
  left: 0; */
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

#tile:nth-child(1) img {
  animation-delay: .3s;
}
#tile:nth-child(2) img {
  animation-delay: 2.1;
}
#tile:nth-child(3) img {
  animation-delay: .9s;
}
#tile:nth-child(5) img {
  animation-delay: 1.1s;
}
#tile:nth-child(6) img {
  animation-delay: 3s;
}
#tile:nth-child(7) img {
  animation-delay: 1.6s;
}
#tile:nth-child(8) img {
  animation-delay: 2s;
}

#login-area img {
  max-width: 200px;
  margin: 10px 0 10px 0;
}

#login-area h3 {
  color: var(--primary-color);
  font-size: 1.2em;
  font-weight:100;
  width: 100%;
  text-align: center;
  margin: 5px auto 30px auto;
}

#OceansTitle {
  text-align: center;
  font-size: 50px;
  color: var(--primary-color);
}

#login-section {
  min-width: var(--w-main-minimum);
  max-width: 30rem;
  padding-right: 10px;
}

#login-container {
  background-color: var(--level-two-background);
  min-width: var(--w-main-minimum);
  width: fit-content;
  position: relative;
  padding: 25px;
  margin: 20px 20px 10px;
}

#login-container hr {
  width: 85%;
}

#login-container input {
  color: var(--primary-text-color);
  font-size: 1.2em;
  padding: 5px 5px 5px 0;
  width: 85%;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--primary-color);
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
}

#signInText {
  background-color: #ffffff;
  border-color: #006994;
  font-size: 20px;
  width: 90%;
  display: block;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}

.buttonCenter {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signInButtons {
  width: 90%;
  background-color: #dbe4ed;
  color: var(--primary-text-color);
  display: block;
  padding: 5px 20px 10px 20px;
  font-size: 1.1em;
  align-items: center;
  justify-content: center;
  margin: 20px 0 10px 0;
  border-radius: 10px;
  border: 2px;
  cursor: pointer;
}

.signInButtons:hover {
  color: var(--button-background-color);
  background-color: var(--primary-hover-color);
}

#line {
  background-color: #006994;
  padding: 1px;
}

#forgotPassword {
  color: var(--primary-color);
  text-decoration: none;
  font-size: .9em;
  text-align: center;
  margin-bottom: 10px;
}

#forgotPassword:hover {
  color: var(--secondary-text-color);
  text-emphasis: bold;
}

::placeholder {
  color: #94b1c9;
}


/********************************************************************/
/********************************************************************/
/************************** HEADER NAVIGATION ***********************/
/********************************************************************/
/********************************************************************/

/* NAV DRAWER */
#nav-drawer-main-container {
  width:320px;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: var(--nav-drawer-background);
  padding-top: 60px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .1), 0px 0px 5px rgba(0, 0, 0, .2);
}

#nav-drawer-header {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

#nav-drawer-header-img-area {
  width: 100%;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--nav-drawer-background);
  margin-bottom: 30px;
}

.nav-drawer-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid var(--nav-drawer-background);
  box-sizing: border-box;
  position: absolute;
  top: 70px;
  left: 35px;
}

#nav-drawer-header-text-area {
  width: 100%;
  height: fit-content;
  padding: 0 20px 0 37px;
  margin: 0;
  box-sizing: border-box;
}

#nav-drawer-header-text-area h1 {
  color: var(--primary-text-color);
  font-size: 1.4em;
  text-align: left;
  line-height: .2em;
}

#nav-drawer-header-text-area p {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: var(--secondary-text-color);
  font-size: .9em;
  text-align: left;
  line-height: 1.3em;
  opacity: .7;
  margin-bottom: 10px;
}

#nav-drawer-stats-slide {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 35px;
  gap: 12px;
}

#nav-drawer-stats-slide p {
  font-size: .8em;
  font-weight: 500;
  color: var(--stat-label);
  margin: 0;
  padding: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  gap: 2px;
}

#nav-drawer-container {
  width: 320px;
  height: 100vh;
  background-color: var(--nav-drawer-background);
  transition: 0.5s;
  padding: 0;
  box-sizing: border-box;
}

#nav-drawer {
  color: var(--nav-drawer-text);
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#nav-drawer-top {
  width: 303px;
  /* flex: 1; */
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-right: 0px;
}

#nav-drawer-top hr {
  opacity: .4;
  width: 80%;
  margin-bottom: 0px;
}

.nav-drawer-collapsible-container {
  width: 80%;
  margin-bottom: 10px;
}

.nav-drawer-collapsible-container button{
  color: var(--nav-drawer-text);
  width: 100%;
  text-align: left;
  border: none;
  padding: 2px 0;
  grid-template-columns: 30px 1fr;
}

.nav-drawer-btn-icon {
  grid-column: 1 / 2;
  align-self: center;
}

.nav-drawer-btn-title {
  grid-column: 2 / 3;
  align-self: center;
}

.nav-drawer-btn-arrow {
  grid-column: 3 / end;
  align-self: center;
}

.nav-drawer-collapsible-container button:hover{
  color: (--nav-drawer-hover);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
}

/* NAV DRAWER HEADER BUTTON */

.nav-drawer-btn {
  font-size: 1.05em;
  width: 300px;
  height: 45px;
  gap: 4px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 15px;
  opacity: .9;
  line-height: 0;
}

.nav-drawer-btn:hover {
  cursor: pointer;
}

.nav-drawer-btn:first-child {
  padding-top: 20px;
}

.collapsible-list {
  font-size: 1em;
  width: 100%;
  margin-left: 15px;
  padding-left: 0px;
  border-left: .5px solid var(--nav-drawer-active-text);
}

.collapsible-list-btn {
  width: 227px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

#nav-drawer-bottom {
  width: 303px;
  height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-top: 2px solid var(--nav-drawer-border);
  padding: 20px 0 20px 0;
  overflow: hidden;
}

#nav-drawer-bottom-btn-area {
  width: 80%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.active {
  color: var(--nav-drawer-active-text) !important;
  /* text-decoration: underline;
  text-underline-offset: .2rem; */
  font-weight: 500 !important;
}



.hub-list-btn {
  color: white;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 0;
}


/* HEADER SEARCH BAR */
#main-nav-container {
  background-color: var(--primary-color);
  width: 100%;
  height: 60px;
  padding: 0px 0px 0px 10px;
  margin: 0px 0px 20px 0px;
  border-radius: 0px;
  grid-template-columns: 250px 1fr 250px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  border: 1px solid var(--primary-color);
}

@media only screen and (max-width: 600px){
  #main-nav-container {
    background-color: var(--primary-color);
    width: 100%;
    height: 60px;
    padding: 0px 10px 0px 0px;
    margin: 0px 0px 20px 0px;
    border-radius: 0px;
    grid-template-columns: 20% 1fr 20%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  
}

.nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon:hover {
  cursor: pointer;
  background-color: var(--message-app-pop-up);
  border-radius: 50%;
  padding: 2px;
  box-sizing: border-box;
}

#nav {
  min-width: 200px;
  grid-column: 1 / 2;
  grid-template-columns: 55px 1fr;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

@media only screen and (max-width: 600px){
  #nav {
    min-width: 200px;
    grid-column: 1 / 2;
    grid-template-columns: 55px 1fr;
    display: flex;
    align-items: center;
  }
}

#nav ul {
  margin: 0;
  padding: 0;
  grid-column: 2 / end;
}

#nav li {
  padding: 0.5em;
  /* margin-top: 10px; */
  display: inline-block;
}

#nav li img {
  opacity: 0.6;
  height: 30px;
}

#nav li img:hover {
  opacity: 0.9;
}

/* HEADER SEARCH BAR */

#main-nav-search-bar-area {
  min-width: 200px;
  max-width: 100%;
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px){
  #main-nav-search-bar-area {
    min-width: 150px;
    max-width: 100%;
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
  } 
}

#main-nav-search-bar-container {
  width: 840px;
}

@media only screen and (max-width:600px){
  #main-nav-search-bar-container {
    width: 840px;
    padding-right: 15px;
  } 
}

/* RIGHT NAVIGATION (NOTIFICATION, MESSAGE, NAME & DROP MENU */
#main-nav-user-alerts-container {
  min-width: 200px;
  grid-column: 3 / end;
  grid-template-columns: 1fr 75px 75px;
}

#main-nav-user-alerts-container ul {
  margin: 0;
  padding: 0;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

#main-nav-user-alerts-container li {
  padding: 0.4em;
  margin-top: 0px;
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#main-nav-user-alerts-container li img {
  height: 30px;
  margin-left: auto;
  opacity: 0.6;
}

#main-nav-user-alerts-container li img:hover {
  opacity: 0.9;
  cursor: pointer;
}

#main-nav-user-alerts-name {
  color: var( --text-over-button);
  opacity: 0.6;
  text-align: right;
  grid-column: 2 / 3;
}

.main-nav-alerts-container {
  width: fit-content;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-nav-alerts-counter {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: 4px;
  right: 0;
  background-color: var(--towson-gold);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-nav-alerts-icon:hover {
  cursor: pointer;
}

.main-nav-alerts-counter p {
  color: var(--primary-color);
  font-size: .6em;
  font-weight: bold;
}

/********************************************************************/
/********************************************************************/
/************************* DROP DOWN MENU ***************************/
/*********************** CODE FROM W3SCHOOLS ************************/
/********************************************************************/
/********************************************************************/

.dropbtn {
  background-color: transparent;
  text-align: left;
  opacity: 0.6;
  color: var( --nav-text-color);
  padding: 16px;
  font-size: 1.4em;
  border: none;
  cursor: pointer;
  grid-column: 3 / end;
}

.search-bar {
  background-color: transparent;
  text-align: left;
  color: #94b1c9;
  font-size: 1.4em;
  cursor: pointer;
  width: 100%;
  color: var(--primary-text-color);
  background-color: var(--button-background-color);
  font-size: 1.1em;
  border: 0px;
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
  outline: 0;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  border-radius: 10px;
  position: relative;
  display: inline-block;
}

.search-dropdown {
  border-radius: 10px;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}

/* Dropdown Content (Hidden by Default) */
#dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  width: 300px;
  min-width: 160px;
  border-radius: 10px;
  z-index: 1;
  top: 40px;
  width: 100%;
  color: var(--level-two-background);
}

/* Links inside the dropdown */
#dropdown-content a {
  color: var(--primary-text-color);
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  text-decoration: none;
  background-color: var(--button-background-color);
  display: block;
}

#dropdown-content button {
  color: var(--primary-text-color);
  width: 100%;
  height: 40px;
  background-color: var(--button-background-color);
  text-decoration: none;
  padding: 12px;
  border-radius: 10px;
  display: block;
}


/* Change color of dropdown links on hover */
#dropdown-content a:hover,
#dropdown-content button:hover {
  color: #dbe4ed;
  background-color: var(--primary-hover-color);
  border-radius: 10px;
}

/* Show the dropdown menu on hover */
.dropdown:hover #dropdown-content,
.search-dropdown:hover #dropdown-content {
  display: block;
  border-radius: 15px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  color: #dbe4ed; 
  border-radius: 10px;
}

/********************************************************************/
/********************************************************************/
/************************* ADMIN CONTROLS ***************************/
/********************************************************************/
/********************************************************************/

#controls-container {
  width: auto;
  height: 100%;
  /* background-color: var(--primary-color); */
  grid-column: 1 / end;
  grid-row: 1 /2;
  margin: 0 0 20px 0;
  padding: 0 20px 0 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

#controls-container h3 {
  font-size: .8em;
  color: var(--nav-text-color);
}

#edit-banner-icon-area {
  width: 20px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 40px;
  z-index: 10;
}

#edit-banner-icon-area img{
  padding: 10px;
  width: 25px;
  opacity: .7;
  border-radius: 50%;
  overflow:visible;
}

#edit-banner-icon-area img:hover {
  opacity: 1;
  background-color: var(--primary-hover-color);
  cursor: pointer;
}

.admin-btns {
  
  font-size: .6em;
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  width: fit-content;
  height: fit-content;
  border-radius: 25px;
  border: 0px;
  cursor: pointer;
  display: inline;
  padding: 5px 10px 5px 10px;
  margin: 0 5px 0 0;
}

.admin-btns:hover {
  color: #dbe4ed;
  background-color: var(--primary-color);
  opacity: 1;
}
/********************************************************************/
/********************************************************************/
/********************* PROFILE & OCEAN HEADER ***********************/
/********************************************************************/
/********************************************************************/

#header-container {
  min-width: var(--w-profile-minimum);
  max-width: var(--w-profile-maximum);
  margin-bottom: 20px;
  padding-top: 0;
  padding-bottom: 20px;
  background-color: var(--level-two-background);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#header-top-container {
  width: 100%;
  position: relative;
}

#header-cover-img {
  width: 100%;
  height: 306px;
  place-self: center;
  background: no-repeat;
  background-size: cover;
}

/* Specifically for Profile Header (below) */
#profile-header-cover-img {
  width: 100%;
  height: 306px;
  place-self: center;
  background: no-repeat;
  background-size: cover;
}

#profile-header-avatar {
  width: 175px;
  height: 175px;
  border: 4px solid var(--level-two-background);
  border-radius: 50%;
  position: absolute;
  top: 180px;
  left: calc(50% - (175px / 2));
}

#profile-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

#profile-header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#profile-header-title h3 {
  font-size: 1.5em;
  color: var(--primary-text-color);
  line-height: 1em;
  margin: 0;
}

#profile-header-title p {
  font-size: .9em;
  color: var(--secondary-text-color);
  line-height: 0;
}
/* Specifically for Profile Header (above) */


#header-middle-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

#header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

#header-title h1 {
  color: var(--primary-text-color);
  line-height: 0;
}

#header-stats-btns-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#header-stats {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

#header-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

#header-bottom-container p {
  font-size: 0.9em;
  text-align: left;
  color: var(--primary-text-color);
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
}

.header-stat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-stat-container p {
  text-align: center;
  color: var(--stat-label);
  font-size: .8em;
  line-height: 1em;
  margin: 0;
  font-weight: 500;
}

.header-stat-container h1 {
  text-align: center;
  font-size: calc(.8em * 1.618);
  color: var(--stat-color);
  line-height: 1.5em;
  margin: 0;
  font-weight: 700;
}

#header-bottom-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  box-sizing: border-box;
}

/********************************************************************/
/********************************************************************/
/***************************** TOPIC HEADER *************************/
/********************************************************************/
/********************************************************************/

#dashboard-topic-container {
  min-width: var(--w-profile-minimum);
  max-width: var(--w-profile-maximum);
  height: var(--profile-header-height);
  margin: 0;
  margin-bottom: 20px; 
}

.follow-btn {
  background-color: var(--primary-color);
  color: var(--text-over-button);
  width: 80px;
  height: 20px;
  border-radius: 25px;
  border: 0px;
}

.follow-btn:hover {
  background-color: var(--primary-hover-color);
  color: var(--button-text-color);
  cursor: pointer;
}

/* TOPIC HEADER SECTION */
#topic-header {
  background-color: gray;
  width: 100%;
  height: 100%;
  border-color: var(--primary-color);
  box-shadow: var(--shadow-toggle);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

#topic-header img {
  max-width: 100%;
  animation: banner-pulse 35s infinite;
}

@keyframes banner-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

#topic-text-container {
  padding: 0px;
  border-radius: 25px;
}

#topic-text-container h1 {
  width: fit-content;
  font-size: 1.3em;
  background-color: rgba(0, 0, 0, .4);
  color: rgba(255, 255, 255, 0.7);
  padding: 0px 17px 8px 20px;
  border-bottom-right-radius: 15px;
  position: absolute;
  top: -14px;
  left: 0;
}

#topic-text-container h1:hover {
  cursor: pointer;
}

#topic-text-container p {
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.7);
  padding: 4px 17px 5px 20px;
  background-color: rgba(0, 0, 0, .4);
  border-top-right-radius: 15px;
  position: absolute;
  bottom: -13px;
  left: 0;
}

/********************************************************************/
/********************************************************************/
/************************ OCEANS HEADER *****************************/
/********************************************************************/
/********************************************************************/

#oceans-header-title{
  font-size: 1.9em;
  text-align: left;
  color: var(--primary-text-color);
  margin: 0px 0px 5px 0px;
  padding: 0;
}



/********************************************************************/
/********************************************************************/
/************************ OCEANS LIST BANNER ************************/
/********************************************************************/
/********************************************************************/

#oceans-list-banner-container {
  min-width: var(--w-profile-minimum);
  max-width: var(--w-profile-maximum);
  height: 118px;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 25px;
  position: relative;
}

#oceans-list-banner-title-container {
  color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 52px;
  font-size: 0.9em;
  text-align: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  justify-content: center;
}

.oceans-list-banner-title-tab {
  font-weight: bold;
  width: 132px;
  height: 52px;
  padding-top: 9px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.oceans-list-banner-title-tab:hover {
  background-color: var(--primary-hover-color);
  color: var(--button-text-color);
  cursor: pointer;
}

.tab-active {
  color: var(--primary-color);
  background-color: var(--primary-hover-color);
}

.tab-inactive {
  color: var(--text-over-button);
  background-color: var(--primary-color);
}

/* REVERT BACK TO THIS SINGLE TAB
#oceans-list-banner-title {
  color: rgba(255, 255, 255, 0.9);
  width: 143px;
  height: 52px;
  font-size: 0.9em;
  text-align: center;
  padding-top: 7px;
  background-color: var(--primary-color);
  border-radius: 25px;
  position: absolute;
  left: 41%;
}*/

#oceans-list-banner-links-container {
  width: 100%;
  height: 80px;
  background-color: var(--level-two-background);
  border-color: var(--primary-color);
  box-shadow: var(--shadow-toggle);
  position: absolute;
  bottom: 0;
}

#oceans-list-buttons-container {
  height: 100%;
  border-radius: 25px;
  grid-template-columns: 60px minmax(0, 1fr) 60px;
}

#oceans-list-container {
  height: 80px;
  grid: 2 / 3;
}

#oceans-list-container-button-area {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.admin-icon {
  position: absolute;
  top: calc(var(--h-hubs-btn) + 22px);
  right: calc(var(--w-hubs-btn) * 50%);
  opacity: .7;
}

#oceans-list-container ul {
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  white-space: nowrap;
  padding: 0 0 0 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

#oceans-list-container li {
  display: inline;
  position: relative;
}

.oceans-btn {
  text-wrap: wrap;
  /* font-size: .95em; */
  font-size: 10pt;
  font-weight: 500;
  color: var(--primary-text-color);
  width: var(--w-hubs-btn);
  height: var(--h-hubs-btn);
  margin: 0 7px 0 7px;
  border-radius: 25px;
  border: 0;
  background-color: var(--button-background-color);
  vertical-align: top;
  /*By default the verical alignment of inline elements is baseline. With top - buttons will now be centered even if text wraps*/
  text-overflow: ellipsis;
  line-height: .9em;
}

.oceans-btn.active {
  background-color: var(--primary-hover-color);
  color: var(--button-text-color);
}

.oceans-btn:hover, #create-ocean-btn:hover {
  border-radius: 25px;
  color: var(--button-text-color);
  background-color: var(--primary-hover-color);
}

#left-btn {
  grid-column: 1 / 2;
  place-self: center;
}

.btn-ease {
  transition: .3s ease;
  cursor: pointer;
}

#right-btn {
  grid-column: 3 / end;
  place-self: center;
}

.directional-btn {
  color: var(--primary-text-color);
  text-align: center;
  font-size: 1.6em;
  font-weight: bold;
  width: 40px;
  height: 40px;
  background-color: var(--button-background-color);
  border-radius: 50%;
}

.directional-btn:hover {
  color: var(--button-background-color);
  background-color: var(--primary-hover-color);
}

/********************************************************************/
/********************************************************************/
/********************** USER POST INPUT MODULE **********************/
/********************************************************************/
/********************************************************************/

#post-preview-container {
  width: 120px;
  height: 70px;
  margin: 5px 5px 10px 5px;
  position: relative;
  z-index: 10;
}

#post-preview-container img {
  max-width: auto;
  max-height:70px;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.close-preview-btn {
  height: 5px;
  width: 5px;
  color: var(--primary-color);
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 11;
  padding: 4px;
  margin: 0;
  font-size: x-small;
  line-height: 2px;
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: #1a1a1a;
  opacity: .8;
}

.close-preview-btn:hover {
  background-color: var(--primary-hover-color);
}

.user-post-btn {
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  width: fit-content;
  height: 30px;
  border-radius: 5px;
  border: 0px;
  padding: 0 15px;
  box-sizing: border-box;
  font-weight: 600;
}

#input-field-container input {
  font-size: 1.1em;
  grid-column: 2 / end;
  outline: none;
  border: 0px;
  border-radius: 25px;
  padding: 10px;
  outline: 0; /* Ensures that when in focus there is no border added*/
  color: var(--primary-text-color);
  background-color: var(--level-three-background);
}

#user-post-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  box-sizing: border-box;
}

#input-field-container {
  width: 100%;
  place-self: center;
  background-color: var(--level-three-background);
  margin-bottom: 10px;
  border-radius: 30px;
  border: 0px;
  height: 60px;
  grid-row: 1 / 2;
  grid-template-columns: 60px;
  z-index: 0;
}

.user-post-image {
  width: 45px;
  height: 45px;
  position: relative;
  top: 5px;
  left: 7px;
  border-radius: 50%;
  grid-row: 1 / end;
  grid-column: 1 / 2;
  border: 2px solid var(--level-two-background);
  z-index: 0;
}

.post-btn-container {
  background-color: transparent;
  display: inline-block;
  grid-row: 2 / end;
}

#post-text,
::placeholder {
  font-size: 1em;
  color: var(--secondary-text-color);
}

/********************************************************************/
/********************************************************************/
/**************************** POST MODULE ***************************/
/********************************************************************/
/********************************************************************/

.post-thumbnail {
  max-width: 100%;
  max-height: 600px;
  margin: 15px 0px 10px 0;
  border-radius: 15px;
}

.post-img {
  margin-top: 10px;
  width: 100%;
  border-radius: 25px;
  z-index: 0;
}

#post-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  max-height:max-content;
  padding: 20px;
  margin: auto;
  margin-bottom: 10px;
  background-color: var(--level-two-background);
  box-shadow: var(--shadow-toggle);
  box-sizing: border-box;
}

#post-title-container {
  place-self: center;
  width: 100%;
  border-radius: 25px;
  border: 0px;
  height: 60px;
  grid-row: 1 / 2;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr 1fr;
  z-index: 1;
}

#post-title-container h3 {
  font-size: 1.1em;
  position: relative;
  top: 10px;
  color: var(--primary-text-color);
  grid-column: 2 / end;
  grid-row: 1 / 2;
  padding: 0;
  margin: 0;
  z-index: 0;
}

#post-title-container p {
  position: relative;
  top: 7px;
  font-size: small;
  color: var(--secondary-text-color);
  grid-column: 2 / end;
  grid-row: 2 / end;
  padding: 0;
  margin: 0;
}

.post-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  top: 7px;
  grid-row: 1 / end;
  grid-column: 1 / 2;
}

.post-content {
  width: 100%;
  font-size: 0.8em;
  max-height:fit-content;
  background-color: transparent;
  margin: 0 0 10px 0;
  color: var(--primary-text-color);
  grid-row: 2 / 3;
  overflow-y: scroll;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.post-content p {
  width: 100%;
  white-space: pre-wrap;
}

.post-content-modal {
  color: black;
  font-size: 1em;
}

.post-content h3 {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 1.2rem;
  background-color: transparent;
  display: block;
}

.insights-container {
  width: 100%;
  margin: auto;
  background-color: transparent;
  grid-row: 3 / end;
}

.insights-btn {
  font-size: 0.6em;
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  width: 55px;
  height: 25px;
  border-radius: 25px;
  border: 0px;
  cursor: pointer;
  margin-right: 5px;
}

.insights-btn-liked {
  font-size: 0.6em;
  color: var(--nav-text-color);
  background-color: var(--like-button-active);
  width: 55px;
  height: 25px;
  border-radius: 25px;
  border: 0px;
  cursor: pointer;
  margin-right: 5px;
}

.insights-btn:hover {
  color: #f6fcff;
  background-color: var(--primary-hover-color);
}

/********************************************************************/
/********************************************************************/
/**************************** POST COMMENTS  ************************/
/********************************************************************/
/********************************************************************/

.modal-thread-container {
  width: 40rem;
  height: 90%;
  padding: 20px;
  background-color: var(--text-over-button);
  z-index: 100;
  position: relative;
  overflow-y: scroll;
  animation-name: expanded-post-modal-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 30;
}

@keyframes expanded-post-modal-pop {
  0% {
    transform: scale(0);
  };
}

.comment-container {
  height: fit-content;
  padding: 20px;
  margin-bottom: 10px;
  position: relative;
}

.comment-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  top: 7px;
  grid-row: 1 / end;
  grid-column: 1 / 2;
}

#modal-post-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px 20px 20px 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  border-color: var(--primary-color);
  box-shadow: var(--shadow-toggle);
  grid-template-rows: 100px 1fr;
}

#comment-input-field-container {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid var(--button-background-color);
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
}

#comment-input-field-container textarea {
  width: 100;
  font-size: 1.1em;
  color: var(--primary-text-color);
  background-color: var(--level-two-background);
  height: 70px;
  border: none;
  resize: none;
  overflow: none;
  outline: none;
  padding: 15px 0;
  opacity: .7;
}

.user-comment-img {
  width: 45px;
  height: 45px;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 50%;
  grid-row: 1 / end;
  grid-column: 1 / 2;
  border: 2px solid var(--level-three-background);
}

.comment-icons-container {
  height: fit-content;
  background-color: var(--level-two-background);
  /* border-bottom: 1px solid var(--button-background-color);
  border-left: 1px solid var(--button-background-color);
  border-right: 1px solid var(--button-background-color); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#comment-title-container {
  width: 100%;
  border-radius: 25px;
  border: 0px;
  height: 60px;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr 1fr;
}

#comment-title-container h3 {
  font-size: 1.1em;
  position: relative;
  top: 10px;
  color: var(--primary-text-color);
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

#comment-title-container p {
  position: relative;
  top: 5px;
  font-size: small;
  color: var(--secondary-text-color);
  grid-column: 2 / end;
  grid-row: 2 / end;
  padding: 0;
  margin: 0;
}

.comment-content {
  height: fit-content;
  font-size: 0.8em;
  color: var(--primary-text-color);
  white-space: pre-wrap;
  text-wrap: wrap;
}

.comment-container:not(:last-child)::after {
  content: "";
  display: block;
  border-bottom: 1px solid var(--level-three-background); /* Replace with desired color and width */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

/* .comment-content:last-child::after {
  visibility: hidden;
} */


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/****************************** COMMON MODAL ******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

.business-modal-container {
  top: 0;
  left: 0;
  width: 100vw;
  min-width: 500px;
  height: auto;
  overflow: auto;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  top: 0;
  left: 0;
  width: 100vw;
  min-width: 500px;
  height: 100vh;
  position: fixed;
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: modal-backdop-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.sg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: modal-backdop-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes modal-backdrop-pop {
  0% {
    transform: scale(0);
  };
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/****************************CREATE USER FORM MODAL************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/


/*NEW CREATE USER FORM (NON MODAL)*/

.create-user-form-content {
  max-width: 550px;
  min-width: 400px;
  animation-name: create-account-modal-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  margin: 10px 0 20px 0;
}

/* LAYOUT 1 COLUMN */
.form-single-column-container {
  min-width: var(--w-main-minimum);
  max-width: var(--w-main-maximum);
  background-color: var(--level-two-background);
  padding: 0;
  display: flex;
  flex-direction: column;
  
}


/* LAYOUT 2 COLUMNS */

.create-account-form-2c-container {
  min-width: var(--w-main-minimum);
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  background-color: var(--level-two-background);
  margin: 10px auto;
}

#create-account-form-2c-header {
  grid-row: 1 / 2;
}

#create-account-form-main-body {
  grid-row: 2 / 3;
  min-width: 90%;
  max-width: 860px;
  max-height: 76vh;
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 25px;
}

#create-account-form-lc {
  width: 100%;
  min-width: 300px;
  max-width: 400px;
}

#create-account-form-rc {
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  height: 100%;
}

.form-body-override {
  padding: 0px 0px !important;
}

@media (max-width: 650px) {
  #create-account-form-main-body {
  flex-direction: column;
  }
  #create-account-form-lc, #create-account-form-rc {
  min-width: 100%;
  max-width: 100%;
  }
  }


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/****************************CREATE POST MODAL*****************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#create-new-post-form-container {
  background-color: var(--level-two-background);
  width: 100%;
  padding: 0px;
  margin-bottom: 20px;
  z-index: 1;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 300px fit-content;
  box-sizing: border-box;
  animation-name: create-post-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

#create-new-post-form-header {
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 15px 0 0;
  padding: 10px 20px;
  z-index: 0;
}

#create-new-post-form-inputs {
  grid-row: 2 / 3;
  height: 300px;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

#create-new-post-form-inputs input{
  color: var(--primary-text-color);
  font-size: 1.2em;
  width: 100%;
  margin: 10px 0 15px 0;
  padding: 10px 0px 10px 0px;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--primary-color);
  border-radius: 0;
  grid-column: 1 / end;
  grid-row: 2 / 3;
  opacity: .7;
}

#create-new-post-btn-container {
  grid-row: 3 / 4;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
}

#create-new-post-btn-submit-cancel-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}


.create-post-modal-content {
  width: 60%;
  min-width: 400px;
  z-index: 40;
  animation-name: create-post-pop;
}

@keyframes create-post-pop {
  0% {
    transform:translateY(10vh);
  }
}

#create-post-form-container {
  background-color: var(--level-two-background);
  width: 100%;
  position: relative;
  border-radius: 25px;
  border: 2px solid var(--primary-color);
  padding: 0px;
  z-index: 50;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr 50px;
}

#create-post-form-inputs, #login-area {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

#create-post-form-inputs input{
  color: var(--primary-text-color);
  font-size: 1.2em;
  width: 85%;
  margin: 10px 0 15px 0;
  padding: 10px 0px 10px 0px;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--primary-color);
  border-radius: 0;
  grid-column: 1 / end;
  grid-row: 2 / 3;
}

#create-post-form-inputs h3 {
  color: var(--primary-text-color);
  width: 85%;

}

#create-post-form-inputs textarea {
  font-size: .9em;
  color: var(--primary-text-color);
  padding: 10px 0px 10px 0px;
  margin-bottom: 40px;
  width: 85%;
  height: 80%;
  border: none;
  resize: none;
  overflow: none;
  outline: none;
  grid-column: 1 / end;
  grid-row: 3 / 4;
}

#create-post-btn-container {
  padding: 20px 0px 20px 40px;
  grid-row: 4 / end;
  grid-column: 1 / end
}

/* .post-text-area {
  height: 100%;
} */


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*****************************EDIT INFO MODAL******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/


#edit-info-modal-container {
  top: 0;
  width: 400px;
  height: 100%;
  position: fixed;
  right: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: edit-modal-slide;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes edit-modal-slide {
  0% { 
    transform: translateX(50vw);
  };
}

#edit-info-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  z-index: 20;
  background: rgba(0, 0, 0, 0.75);
}

#edit-info-modal-content {
  background-color: var(--level-two-background);
  width: 80%;
  height: 90%;
  border-radius: 25px;
  border: 2px solid var(--primary-color);
  padding: 0px;
  z-index: 30;
  display: flex;
  align-items: left;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#edit-info-modal-content::-webkit-scrollbar {
  display: none;
}

#edit-info-form-container {
  width: 100%;
  padding: 20px;
}

#edit-info-form-container h2 {
  padding: 0px;
  margin: 0 0 10px 0;
}

#edit-info-form-container label {
  font-size: .9em;
  display: block;
}

#edit-info-form-container input {
  width: 80%;
  padding: 5px;
  margin-bottom: 20px;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--primary-color);
  display: block;
}

#edit-info-form-container textarea {
  font-size: .9em;
  color: var(--primary-text-color);
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 265px;
  height: 150px;
  border: .5px solid var(--primary-color);
  resize: none;
  overflow: none;
  outline: none;
}

#edit-info-form-container button, #edit-oceans-banner-container button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border-radius: 15px;
  width: 80px;
  height: 30px;
  margin-bottom: 20px;
  border: none;
  outline: none;
}

#edit-info-form-container button:hover, #edit-oceans-banner-container button:hover {
  background-color: var(--primary-color);
  color: var(--button-background-color);
  cursor: pointer;
}


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*****************************MY ACCOUNT PAGE******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#my-account-container {
  display: flex;
  flex-direction: row;
}

/* Left Flex Column */
.my-account-icons {
  padding: 5px;
  margin: 10px;
  min-width: 20px;
  max-width: 40px;
  overflow: visible;
}

#my-account-lc {
  padding: 5px;
  height: 100vh;
  margin-right: 30px;
  min-width: 200px;
  max-width: 300px;
}

#my-account-lc ul {
  padding-left: 60px;
}

#my-account-lc li {
  color: var(--primary-text-color);
  font-size: 1.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#my-account-lc li:hover {
  color: var(--secondary-text-color);
  cursor: pointer;
}

.my-account-icons:hover {
  background-color: var(--primary-color);
  border-radius: 50%;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*******************************PERSONAL INFO******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

/* Right Flex Column */
#my-account-rc {
  color: var(--primary-text-color);
  padding: 20px;
  height: fit-content;
  width: 70%;
}

#my-account-rc fieldset {
  width: 60%;
  border: none;
  padding: 1em 0px 1em 0px;
}

#basic-info-table {
  width: 100%;
  min-width: 30rem;
}

#basic-info-table caption {
  font-size: 1.3em;
  text-align: left;
  padding: 10px 20px 10px 0;
  height: 40px;
  border-bottom: 1px solid var(--primary-text-color);
}

.row {
  color: var(--primary-text-color);
  font-size: 1em;
  padding: 10px 20px 10px 0;
  height: 40px;
  border-bottom: 1px solid var(--primary-text-color);
}

.row-key {
  font-weight: bold;
  width: 30%;
}

.row-value {
  width: 100%;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*****************************ACCOUNT SETTINGS*****************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#account-settings-container {
  width: 60%;
  min-width: 500px;
  padding: 10px 0 20px 0;
}

#account-settings-container h3 {
  color: var(--primary-text-color);
  margin-bottom: 30px;
}

#account-settings-container label {
  color: var(--primary-text-color);
  font-size: 1.1em;
  display: block;
  margin-bottom: 10px;
}

#account-settings-container input {
  border: 1px solid var(--primary-color);
  width: 230px;
  padding: 10px;
  font-size: 1.2em;
  margin-bottom: 30px;
  display: block;
  outline: none;
}

#account-settings-container button {
  color: var(--primary-text-color);
  background-color: var(--button-background-color);
  font-size: 1.2em;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 25px;
  grid-row: 4 / end;
  justify-self: center;
  margin: 0 0 20px 0;
  cursor: pointer;
}

#account-settings-container button:hover {
  color: var(--button-background-color);
  background-color: var(--primary-color);
}

.edit-icon {
  float: right;
  padding: 10px;
  width: 25px;
  opacity: .3;
  border-radius: 50%;
  overflow: visible;
}

.edit-icon:hover {
  background-color: var(--primary-color);
  opacity: 1;
  cursor: pointer;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/***************************** VIDEOS *****************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

video{
  width: 100%;
  height: 100%;
  border-radius: 25px;
  margin-top: 10px;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/************************************FOOTER********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

footer {
  color: var(--secondary-text-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer ul, footer p {
  font-size: .75em;
  margin: 2px;
  padding: 2px;
}

footer ul li {
  margin: 0 10px 0 10px;
  display: inline-block;
  height:fit-content;
}

footer ul li:hover {
  color: var(--primary-text-color);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: .2rem;
}

footer li a {
  color: var(--primary-color);
  text-decoration: none;
}

footer li a:hover {
  color: var(--primary-color);
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*******************************CHILD REQUEST******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#child-request-container {
  column-gap: 50px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
}

#child-request-listings-area {
  width: fit-content;
  grid-template-columns: 90px 1fr;
  grid-template-rows: 1fr 1fr 20px;
  color: var(--secondary-text-color);
}

#child-request-listings-area img {
  width: 80px;
  height: 40px;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  margin-top: 5px;
  object-fit: cover;
}

#child-request-listings-area p{
  line-height: 0px;
  font-size: .8em;
  margin-top: 5px;
  grid-column: 2 / end;
  grid-row: 2 / end;
}

#child-request-stats-title {
  font-size: .8em;
  line-height: 0px;
  margin: 0;
  grid-column: 2 / end;
  grid-row: 1 / 2;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#child-request-btns {
  grid-column: 1 / end;
  grid-row: 3 / end;
  display: flex;
  align-items: center;
  justify-content: center;
}


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/******************************MANAGE MEMBERS******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

/* THE MEMBERS CSS BELOW THIS LINE IS ALL NEW AS OF 6/12/2024 */

.member-listing-container {
  width: 100%;
  height: 450px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.member-card-container {
  width: 160px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 40px;
  border-radius: 15px;
}

.member-card-avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid var(--level-two-background);
  z-index: 1;
  position: relative;
  top: -5px;
}

.member-info {
  width: 100%;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 40px 5px;
  background-color: var(--level-three-background);
  box-sizing: border-box;
  z-index: 0;
  position: relative;
  top: 0px;
  border-radius: 10px 10px 0 0;
}

.member-info h3{
  font-size: .9em;
  color: var(--primary-text-color);
  margin: 0;
}

.member-info p{
  font-size: .8em;
  color: var(--secondary-text-color);
  margin: 0;
}

.member-card-btn-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 10px 5px 10px;
  border-radius: 0 0 10px 10px;
  z-index: 1;
  position: relative;
  top: 0px;
  box-sizing: border-box;
  background-color: transparent;
  border: 1.5px solid var(--level-three-background);
}

/* THE MEMBERS CSS ABOVE THIS LINE IS ALL NEW AS OF 6/12/2024 */


#manage-members-container {
  column-gap: 10px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
  flex-wrap: wrap;
  
}

#manage-members-listings-area {
  width: fit-content;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr auto auto;
  color: var(--secondary-text-color);
  padding: 20px;
}

#manage-members-listings-area img {
  width: 50px;
  height: 50px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  margin: 5px;
  object-fit: cover;
}

#manage-members-stats-title {
  font-size: .8em;
  line-height: 0px;
  margin-left: 5px;
  grid-column: 2 / end;
  grid-row: 1 / 2;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#manage-members-stats-title p{
  line-height: 0px;
  font-size: .9em;
  margin-top: 20px;
  grid-column: 2 / end;
  grid-row: 2 / 3;
}

#manage-members-btns {
  grid-column: 1 / end;
  grid-row: 3 / end;
  display: flex;
  align-items: center;
  justify-content: center;
}


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*******************************NOTIFICATIONS******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

.notifications-container {
  width: fit-content;
  max-width: 300px;
  min-width: 260px;
  min-height: 190px;
  height: fit-content;
  max-height: calc(86.5vh);
  padding: 20px;
  position: fixed;
  top: 55px;
  right: 10px;
  z-index: 120;
  background-color: var(--level-two-background);
  padding: 10px 20px;
  box-sizing: border-box;
}

#notification-area {
  width: 100%;
  grid-template-columns: 60px 1fr;
  grid-template-rows: fit-content;
  margin-bottom: 20px;
  overflow: hidden;
}

#notification-area:last-child {
  margin-bottom: 0;
}

#notification-area img {
  grid-column: 1 / 2 ;
  grid-row: 1 / end;
  border: 2px solid var(--primary-color);
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

#notification-text-area {
  grid-column: 2 / 3;
  grid-row: 1 / end;
  grid-template-columns: 15px 1fr;
  grid-template-rows: fit-content 10px;
}

#notification-area h6 {
  font-size: .9em;
  font-weight: 400;
  margin: 0;
  color: var(--primary-text-color);
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

#notification-details {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#notification-area p {
  font-size: .8em;
  line-height: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--towson-gold);
  margin-top: 10px;
  margin-bottom: 5px;
}

#notification-new-alert-marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  background-color: var(--towson-gold);
  margin-top: 5px;
}


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/***********************************CLUBS**********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/


#clubs-orgs-field-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#club-org-container {
  width: 100%;
  height: 350px;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  overflow:hidden;
  box-shadow: var(--shadow-toggle);
}

#club-org-container:last-child {
  margin-bottom: 0;
}

#club-text-field {
  width: 20rem;
  margin: 0 10px 0 0;
  text-wrap: wrap;
  position: relative;
  z-index: 1;
}

#club-text-field h1 {
  color: white;
  font-size: 2em;
  line-height: 1em;
}

#club-text-field p {
  color: whitesmoke;
  font-size: .8em;
  line-height: 1em;
}

#club-org-cover-img-container-inset-shadow {
  background: linear-gradient(to left,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  width: 100%;
  height: 100%;
  border-radius: 25px;
  position: absolute;
  opacity: .8;
  top: 0;
  left: 0;
  z-index: 1;
}

#club-org-cover-img-container img {
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.infinite-scroll-component__outerdiv{
  width: 100%;
  border-radius: 25px;
}

.infinite-scroll-component {
  border-radius: 25px;
  padding: 2px;
}

#club-category {
  width: 90%;
  border-style: none;
  outline: none;
  font-size: 1em;
  color: var(--primary-text-color);
}

#club-category option {
  background-color: var(--button-background-color);
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/******************************ORGS SEARCH*********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/


#orgs-search-container {
  width: 400px;
  padding: 20px 0 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--level-three-background);
}

.orgs-search-field {
  background-color: var(--level-four-background);
  width: 80%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orgs-search-field:last-child {
  margin-bottom: 0;
}

.clubs-search-bar {
  background-color: transparent;
  text-align: left;
  width: 87%;
  color: #94b1c9;
  font-size: 1em;
  cursor: pointer;
  color: var(--primary-text-color);
  border: 0px;
  border-radius: 15px;
  padding: 5px 20px 5px 20px;
  outline: 0;
}

#org-text-field {
  width: 25em;
  margin: 10px;
  text-wrap: wrap;
  position: relative;
  z-index: 1;
}

#org-text-field h1 {
  color: white;
  text-align: left;
  font-size: 2.8em;
  text-shadow: 0px 0px 2px black;
  line-height: 1em;
}

#org-text-field p {
  color: whitesmoke;
  text-align: left;
  font-size: 1em;
  /* line-height: .5em; */
  text-shadow: 0px 0px 2px black;
  margin: 0;
}

#org-text-field p:hover {
  color: var(--primary-hover-color);
  cursor: pointer;
}

#org-text-field a{
  text-decoration: none;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/******************************BUSINESS CATEGORY***************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#club-org-listing-plus-info-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

#club-org-listing-plus-info-container:last-child {
  margin-bottom: 0;
}

#business-login-container{
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#business-login-section {
  max-width: 30rem;
}

@media only screen and (max-width: 600px) {
  #business-login-container {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

#business-container {
  width: 100%;
  height: 350px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  top: 0;
  left: 0;
  overflow:hidden;
  box-shadow: var(--shadow-toggle);
  z-index: 1;
}

#business-category {
  width: 90%;
  border-style: none;
  outline: none;
  font-size: 1em;
  color: var(--primary-text-color);
}

#business-category option {
  background-color: var(--button-background-color);
}

#business-info-slide {
  background-color: var(--level-two-background);
  width: 90%;
  height: auto;
  overflow: hidden;
  display: none;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding: 10px;
}

#business-info-container {
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr 1fr; 
}

#business-info-buttons-area {
  grid-column: 1 / end;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

#business-info-buttons-area button {
  background-color: var(--button-background-color);
  font-size: 1.1em;
  color: var(--primary-text-color);
  width: 15rem;
  height: 3rem;
  margin: 0 15px 0 15px;
  border: none;
  outline: none;
  
  --shadow-toggle: 0px 3px 6px rgba(26, 26, 26, 0.6);
  border: 1px solid var(--primary-color);
  border-radius: 25px;
  box-shadow: var(--shadow-toggle);;
}

#business-info-buttons-area button:hover {
  background-color: var(--primary-color);
  color: var(--nav-text-color);
  cursor: pointer;
}

#business-info-area {
  color: var(--primary-text-color);
  grid-column: 1 / end;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*******************************BUSINESS PORTAL****************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#create-business-account-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 30rem;
}

#current-business-main-container {
  min-width: 450px;
  max-width: 810px;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
}

#business-nav-container {
  background-color: var(--primary-color);
  width: 100%;
  height: 60px;
  padding: 0px 0px 0px 10px;
  margin: 0px 0px 20px 0px;
  border-radius: 0px;
  grid-template-columns: 250px 1fr;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

#business-nav {
  color: white;
  min-width: 200px;
  grid-column: 1 / 2;
  grid-template-columns: 55px 1fr;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px){
  #business-nav {
    min-width: 200px;
    grid-column: 1 / 2;
    grid-template-columns: 55px 1fr;
  }
}

#business-nav ul {
  width: 100%
}

#business-nav ul li {
  margin: 0 10px 0 10px;
  display: inline-block;
  height:fit-content;
}

#business-nav ul li:hover:last-child {
  color: var(--primary-hover-color);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: .2rem;
}

#business-portal-text-area {
  box-sizing: border-box;
  background-color: white;
  grid-template-rows: 40px 1fr;
  margin-bottom: 40px;
}

#business-preview-container {
  width: 810px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#business-slide-expand {
  color: white;
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  opacity: .6;
}

.create-business-form-content {
  width: 90%;
  min-width: 400px;
  animation-name: create-account-modal-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  margin: 10px 0 20px 0;
}

@media only screen and (max-width:600px){
  .create-business-form-content {
    width: 80%;
    animation-name: create-account-modal-pop;
    animation-duration: .7s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 30;
    margin-left: auto;
    margin-right: auto;
  } 
}

.create-business-form-body {
  padding: 25px 40px 30px 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

#business-forms-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 40px 0 20px 0;
}

.form-container {
  min-width: 480px;
  width: 500px;
  height: fit-content;
  box-sizing: border-box;
}

.form-header {
  color: var(--primary-text-color);
  font-weight: bold;
  font-size: 1.3em;
  height: 40px;
  background-color: var(--primary-hover-color);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-body-img-area {
  width: 100%;
  min-height: fit-content;
  height: 190px;
  background-color: var(--button-background-color);
  position: relative;
  padding: 0;
}

.form-body-img-area img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
  object-fit: cover;
}

.form-body-profile-img-area {
  width: 100%;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  top: calc(95% / 2);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-body-profile-img-btn-container {
  width: 130px;
  position: relative;
}

.form-body-profile-img-area img {
  width: 130px;
  height: 130px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 50%;
  border: 4px solid var(--level-two-background);
  box-sizing: border-box;
}

.form-body {
  padding: 25px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--level-two-background);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  width: 100%;
}

.form-body h3 {
  width: 100%;
  text-align: left;
}

.form-body label {
  font-size: .8em;
  font-weight: bold;
}

.form-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  margin-bottom: 15px;
  margin-right: auto;
}

.form-select {
  width: 100%;
  font-size: .9em;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: var(--button-background-color);
  display: block;
  box-sizing: border-box;

}

.form-checkbox label {
  font-size: .9em;
  color: var(--primary-text-color);
  margin-right: 10px;
  padding: 0;
  white-space: nowrap;
}

.form-checkbox input[type='checkbox'] {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.form-label-input-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 2px;
}

.form-label-input-container input {
  color: var(--primary-text-color);
  width: 100%;
  font-size: .9em;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: var(--button-background-color);
  display: block;
  box-sizing: border-box;
}

.form-label-input-container textarea {
  width: 100%;
  font-size: .9em;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: var(--button-background-color);
  display: block;
  box-sizing: border-box;
}

.form-instructions {
  font-size: 1em;
  line-height: 1.5rem;
  padding: 10px 20px;
}

.form-instructions h3 {
  color: var(--primary-text-color);
}

.vpass-label {
  width: 100%;
}

.form-body input {
  width: 100%;
  font-size: .9em;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: var(--button-background-color);
  display: block;
  box-sizing: border-box;
}

.form-body textarea {
  font-size: .9em;
  color: var(--primary-text-color);
  padding: 15px 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 125px;
  border-radius: 5px;
  border: none;
  background-color: var(--button-background-color);
  resize: none;
  overflow: none;
  outline: none;
  display: block;
  box-sizing: border-box;
  resize: none;
}

#form-body-btn-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.form-body button {
  min-width: 125px;
  max-width: 150px;
  font-size: .9em;
  font-weight: bold;
  border: none;
  height: 50px;
  border-radius: 5px;
}

.form-body-btn {
  width: 100px;
  height: 40px;
  font-size: .9em;
  border: none;
  border-radius: 5px;
  background-color: var(--button-background-color);
  box-sizing: border-box;
}

.form-body-add-btn {
  color: var(--primary-color);
  background-color: var(--button-background-color);
}

.form-body-cancel-btn {
  color: var(--primary-text-color);
  background-color: var(--button-background-color);
  margin: 0;
}

.form-body-create-btn {
  color: var(--page-background);
  background-color: var(--secondary-text-color);
  margin: 0;
}

.form-body button:hover {
  color: var(--primary-text-color);
  background-color: var(--primary-hover-color);
}

.create-business-form-body label {
  font-size: .8em;
  font-weight: bold;
  display: block;
  width: 100%;
}

.create-business-form-body input {
  width: 100%;
  font-size: .9em;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: var(--button-background-color);
  display: block;
  box-sizing: border-box;
}

.create-business-form-body textarea {
  font-size: .9em;
  color: var(--primary-text-color);
  padding: 15px 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 110px;
  border-radius: 5px;
  border: none;
  background-color: var(--button-background-color);
  resize: none;
  overflow: none;
  outline: none;
  display: block;
  box-sizing: border-box;
}

.create-business-form-body button{
  color: var(--page-background);
  font-size: .9em;
  font-weight: bold;
  width: 100%;
  height: 50px;
  border: none;
  background-color: var(--primary-color);
  border-radius: 5px;
}

.create-business-form-body button:hover{
  color: var(--primary-text-color);
  background-color: var(--primary-hover-color);
}

.column {
  width: 100%;
  float: left;
  padding: 10px;
}

#password-policies-container {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  box-sizing: border-box;
}

#password-policies-container p {
  font-size: .9em;
  color: var(--primary-text-color);
  opacity: .7;
  line-height: 0;
}

#password-policies-container ul {
  margin: 0;
  padding: 0;
}

#password-policies-container li {
  margin-bottom: 5px;
}

.pass-policy {
  font-size: .8em;
  color: var(--primary-text-color);
  opacity: .7;
  box-sizing: border-box;
  margin-left: 15px;
}

.form-message-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  background-color: var(--level-three-background);
  padding: 10px;
  box-sizing: border-box;
  font-weight: 600;
  border-radius: 10px;
}

.form-message {
  color: var(--secondary-text-color);
  font-size: 1em;
  font-style: italic;
  line-height: 1.5em;
  margin: 5px 0;
}



/*NEW FORMS*/
/* I'm trying to simplify ALL forms to use most of the same containers */
.new-form-container {
  min-width: 480px;
  max-width: 800px;
  box-sizing: border-box;
  z-index: 100;
  overflow: hidden;
}

.new-form-header {
  color: var(--primary-text-color);
  font-weight: bold;
  font-size: 1.3em;
  height: 40px;
  background-color: var(--primary-hover-color);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-form-body {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--level-two-background);
  border-radius: 0 0 15px 15px;
  box-sizing: border-box;
  gap: 10px;
}

.new-form-body-2c {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: var(--level-two-background);
  border-radius: 0 0 25px 25px;
  box-sizing: border-box;
  gap: 20px;
}

.new-form-col {
  min-width: 300px;
  width: 48%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 675px){
  .new-form-col {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
  }
}

.form-body-label-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
}

.form-body-label-area label {
  color: var(--secondary-text-color);
  font-size: .8em;
  font-weight: bold;
}

.form-label-input-container input {
  width: 100%;
  font-size: .9em;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: var(--button-background-color);
  display: block;
  box-sizing: border-box;
}

.form-label-input-container textarea {
  font-size: .9em;
  color: var(--primary-text-color);
  padding: 15px 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 125px;
  border-radius: 5px;
  border: none;
  background-color: var(--button-background-color);
  resize: none;
  overflow: none;
  outline: none;
  display: block;
  box-sizing: border-box;
  resize: none;
}

.form-instruction-text {
  font-size: 1em;
  color: var(--secondary-text-color);
  margin: 0;
  line-height: 1.3em;
  font-weight: 600;
  white-space: pre-wrap;
}

.helper {
  color: var(--secondary-text-color);
  opacity: .5;
  font-weight: 600 !important;
  text-align: flex-end;
}


.new-form-radial-btns-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
}

.new-form-radial-btns-area h3 {
  font-size: 1em;
  color: var(--primary-text-color);
  margin: 0;
  margin-bottom: 10px;

}

.radial-btn-and-label {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************CLASSES*********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#classroom-container {
  background-color: var(--level-two-background);
  width: auto;
  min-height: 600px;
  border-radius: 25px;
  grid-template-columns: 10em auto;
  grid-template-rows: 1fr;
  padding: 10px;
}

/***********************Class List Panel*************************/
#classroom-left-panel {
  background-color: var(--primary-color);
  color: var(--text-over-button);
  grid-column: 1 / 2;
  grid-row: 1 / end;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 15px;
}

#classroom-left-panel h1 {
  font-size: 1.2em;
  line-height: 0;
  margin-bottom: 20px;
}

#course-code-collapsible-container button{
  color: var(--text-over-button);
  width: 100%;
  text-align: left;
  border: none;
  padding: 0;
}

#course-code-collapsible-container button:hover{
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
}

#course-code-btn {
  width: 100%;
  color: var(--primary-text-color);
  margin-bottom: 10px;
  border: none;
  font-weight: bold;
}

.channel-btn{
  width: 100%;
  font-size: .9em;
  font-weight: normal;
  border: none;
  margin-left: 20px;
  margin-bottom: 3px;
}

.channel-btn:last-child {
  margin-bottom: 5px;
}

#course-code-collapsible-container hr {
  width: 100%;
  opacity: .4;
}

/***********************Classroom Panel*************************/
#classroom-main-panel {
  color: var(--primary-text-color);
  grid-column: 2 / end;
  grid-row: 1 / end;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  grid-template-rows: 1fr auto;
}

#classroom-field {
  max-height: 100vh;
  /* background: var(--text-over-button); */
  background-color: var(--page-background);
  grid-row: 1 / 2;
  border-top-right-radius: 25px;
  padding: 10px;
  overflow: scroll;
}

#classroom-message-field {
  min-height: 75px;
  background: var(--primary-color);
  grid-row: 2 / end;
  border-bottom-right-radius: 25px;
  padding: 10px;
}

.course-title {
  font-weight: bold;
  font-size: .9em;
}

.active-course {
  text-decoration: underline;
  font-weight: bold;
  text-underline-offset: 3px;
}

.active-channel {
  text-decoration: underline;
  font-weight: 600;
  text-underline-offset: 3px;
}


#channel-message-container {
  min-width: 200px;
  max-width: 100%;
  max-height:max-content;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  background-color: var(--level-two-background);
  /* box-shadow: var(--shadow-toggle); */
  border-radius: 15px;

  grid-template-rows: auto 1fr;
}

#channel-message-title-container {
  grid-row: 1 / 2;
  grid-template-columns: 45px 1fr;
  grid-template-rows: 45px 1fr;
  padding: 0;
  margin-bottom: 10px;
}

.channel-user-avatar {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  top: 10px;
  grid-column: 1 / 2;
}

#channel-message-title-heading {
  margin-left: 7px;
  grid-column: 2 / 3;
  grid-template-rows: 1fr 1fr;

}

#channel-message-title-container h3 {
  font-size: 1em;
  position: relative;
  top: 5px;
  color: var(--primary-text-color);
  grid-row: 1 / 2;
  padding: 0;
  margin: 0;
  z-index: 0;
}

#channel-message-title-container p {
  width: 100%;
  position: relative;
  top: 5px;
  font-size: .7em;
  color: var(--secondary-text-color);
  grid-row: 2 / 3;
  margin: 0;
}

#channel-message {
  width: 100%;
  font-size: 0.8em;
  max-height:fit-content;
  background-color: transparent;
  margin: 0;
  color: var(--primary-text-color);
  grid-row: 3 / 4;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************STUDY GROUPS****************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#study-groups-field {
  background-color: var(--level-two-background);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: center;
}

#study-group-container {
  background-color: var(--level-two-background);
  width: 100%;
  height: 50%;
  /* min-height: 600px; */
  border-radius: 25px;
  grid-template-columns: 10em auto;
  grid-template-rows: 1fr;
  padding: 10px;
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: lightgray;
}

#study-group-container:not(:last-child){
  margin-bottom: 2%;
}

#sg-image-container{
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 2%;
  /* border: 1px solid blue; */
  overflow: hidden;
}

#sg-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 50%; */
}

#sg-text-field{
  width: 50%;
  height: 100%;
  float: right;
  /* border: 1px solid green; */
}

#sg-text-field h1{
  font-size: 1em;
}

#sg-text-field p{
  font-size: .9em;
}

#sg-button{
  margin-left: auto;
}

.sg-listing-container {
  width: 100%;
  height: 115px;
  background-color: var(--level-three-background);
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 60px 15px 15px 60px;
  box-sizing: border-box;
}

.sg-listing-text-area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-top: 5px;
  gap: 5px;
}

.sg-listing-text-area h3 {
  color: var(--primary-text-color);
  font-size: 1em;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.sg-listing-text-area p {
  color: var(--secondary-text-color);
  font-size: .8em;
  margin: 0;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.sg-listing-container img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  box-sizing: border-box;
  object-fit: cover;
  border: 5px solid var(--level-two-background);
  box-sizing: border-box;
}

.sg-button-container {
  color: var(--secondary-text-color);
  width: 100%;
  height: fit-content;
  margin: 0;
  padding-bottom: 5px;
}

.sg-action-btn-area {
  display: flex;
  align-items: center;
  gap: 2px;
}

.sg-action-btn-area span {
  font-size: .8em;
  font-weight: 600;
  color: var(--secondary-text-color);
}


/***********************Study Group Panel*************************/

#study-group-left-panel {
  background-color: var(--button-background-color);
  color: var(--primary-text-color);
  grid-column: 1 / 2;
  grid-row: 1 / end;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 15px;
}

#study-group-left-panel h1 {
  font-size: 1.2em;
  line-height: 0;
  margin-bottom: 20px;
}

#study-group-name-collapsible-container button{
  color: var(--primary-text-color);
  width: 100%;
  text-align: left;
  border: none;
  padding: 0;
}

#study-group-name-collapsible-container button:hover{
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
}

#study-group-name-collapsible-container hr {
  width: 100%;
  opacity: .4;
}

#study-group-name-btn {
  width: 100%;
  color: var(--primary-text-color);
  margin-bottom: 10px;
  border: none;
  font-weight: bold;
}

.active-study-group {
  text-decoration: underline;
  font-weight: bold;
  text-underline-offset: 3px;
}

.study-group-title {
  font-weight: bold;
  font-size: .9em;
}

#study-group-main-panel {
  color: var(--primary-text-color);
  grid-column: 2 / end;
  grid-row: 1 / end;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  grid-template-rows: 1fr auto;
}

#study-group-field {
  max-height: 100vh;
  background: var(--text-over-button);
  grid-row: 1 / 2;
  border-top-right-radius: 25px;
  padding: 10px;
  overflow: scroll;
}

#study-group-message-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  max-height:max-content;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  background-color: var(--level-two-background);
  /* box-shadow: var(--shadow-toggle); */
  border-radius: 15px;

  grid-template-rows: auto 1fr;
}

#study-group-message {
  width: 100%;
  font-size: 0.8em;
  max-height:fit-content;
  background-color: transparent;
  margin: 0;
  color: var(--primary-text-color);
  grid-row: 3 / 4;
}

#study-group-message-field {
  min-height: 75px;
  background: var(--button-background-color);
  grid-row: 2 / end;
  border-bottom-right-radius: 25px;
  padding: 10px;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************CALENDAR********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#calendar-container {
  border: 1px solid red;
}

#store-orgs-field-container, #University-Store-Anchor {
  width: 100%;
  text-decoration: none;
}

.rbc-calendar{
  background: var(--level-two-background) !important;;
}

.rbc-btn-group > button {
  color: var(--primary-text-color) !important;
}

.rbc-btn-group > button:hover {
  color: black !important;
}

.rbc-btn-group > .rbc-active {
  color: black !important;
  background-color: var(--like-button-active) !important;
}

.rbc-day-bg {
  background-color: var(--page-background) !important;
}

.rbc-today {
  background-color: rgba(0, 119, 255, 0.4) !important;
}

.rbc-date-cell {
  color: var(--primary-text-color) !important;
}

.rbc-now {
  color: black !important;
}



.rbc-event {
  color: black !important;
  background-color: rgb(255, 187, 0) !important;
}

.rbc-button-link, .rbc-header, .rbc-agenda-date-cell, .rbc-agenda-time-cell, .rbc-agenda-event-cell, .rbc-agenda-empty, .rbc-month-header, .rbc-toolbar-label, .rbc-label {
  color: var(--primary-text-color) !important;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************COLLEGES********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#college-field-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#college-listing-plus-info-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

#college-listing-plus-info-container:last-child {
  margin-bottom: 0;
}

#college-container {
  width: 100%;
  height: 350px;
  /* margin: 0 0 20px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  box-shadow: var(--shadow-toggle);
}

#college-container h1{
  color: white;
  font-size: 2.5em;
  line-height: 1em;
  text-shadow: 0px 0px 2px black;
  padding-left: 10px;
}

#college-container:last-child {
  margin-bottom: 0;
}

#college-info-slide {
  /* background-color: var(--container-background-color); */
  background-color: lightgray;
  width: 90%;
  height: auto;
  overflow: hidden;
  display: none;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color); */
  padding: 10px 10px 10px 10px;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************DEPARTMENT******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#department-container {
  width: 100%;
  height: 350px;
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  overflow:hidden;
  background-color: white;
  box-shadow: var(--shadow-toggle);
}

#department-text-field {
  width: auto;
  margin: 0 10px 0 10px;
  text-wrap: wrap;
  position: relative;
  z-index: 1;
  /* border: 2px solid red; */
}

#department-text-field h3 {
  color: #FFBB00;
  font-size: 2em;
  line-height: 1em;
}

#department-text-field p {
  color: black;
  font-size: .8em;
  line-height: 1em;
}

#department-info-container {
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr 1fr;
}

#department-info-area {
  color: var(--primary-text-color);
  grid-column: 1 / end;
  /* background-color: gray; */
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************STORE***********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#store-button-container {
  width: 100%;
  height: 350px;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  box-shadow: var(--shadow-toggle);
}

#store-button-container h1{
  color: black;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************JOBS************************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#job-field-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#job-field-container h1 {
  color: #FFBB00;
}

#job-container {
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  background-color: white;
  box-shadow: var(--shadow-toggle);
}

#job-text-field p {
  color: black;
  font-size: .99em;
  /* line-height: 1em; */
}

#job-text-field a:hover{
  color: #FFBB00;
}

#job-text-field ul li::before {
  content: "> ";
}


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**************************REUSABLE INFO SLIDES****************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#info-slide {
  background-color: var(--level-two-background);
  width: 90%;
  height: auto;
  overflow: hidden;
  display: none;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding: 10px;
  margin-bottom: 20px;
}

#cards-field-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#card-container {
  background-color:#989898 ;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  overflow:hidden;
  box-shadow: var(--shadow-toggle);
  z-index: 1;
}

#card-text-field {
  color: white;
  width: 65%;
  margin: 10px 15px 15px 0;
  text-wrap: wrap;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

#card-text-field p {
  font-size: 2em;
  text-align: right;
  line-height: 1.2em;
  margin: 0;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**************************SPORTS******************************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#athletics-field-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#athletics-field-container h1 {
  color: #FFBB00;
}

#athletics-container {
  width: 100%;
  height: auto;
  /* margin: 0 0 20px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  background-color: var(--level-three-background);
  box-shadow: var(--shadow-toggle);
}

#athletics-container h1 {
  color: #FFBB00;
}

#athletic-listing-plus-info-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

#athletic-listing-plus-info-container:last-child {
  margin-bottom: 0;
}

#athletics-text-field {
  width: auto;
  margin: 10px;
  text-wrap: wrap;
  position: relative;
  z-index: 1;
}

#athletics-text-field p {
  color: var(--primary-text-color);
  font-size: .99em;
  /* line-height: 1em; */
}

#athletics-text-field a:hover{
  color: #FFBB00;
}

#athletics-text-field ul li::before {
  content: "> ";
}

#athletic-buttons-container {
  display: flex;
  /* flex-wrap: wrap;  //used for when we want the buttons to wrap the a new line, but i dont think we want that */
  justify-content: space-evenly;
}

#athletics-info-slide {
  /* background-color: var(--container-background-color); */
  background-color: var(--level-three-background);
  width: 90%;
  height: auto;
  overflow: hidden;
  display: none;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color); */
  padding: 10px 10px 10px 10px;
}

#athletics-search-container {
  width: auto;
  padding: 20px 0 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 1px solid red; */
}

.athletics-search-field {
  background-color: var(--level-two-background);
  width: 80%;
  margin-bottom: 20px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.athletics-search-field select {
  padding: 10px;
  background-color: var(--level-three-background);
  border-radius: 15px;
}

.athletics-search-field:last-child {
  margin-bottom: 0;
}

#athletic-container {
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  display: grid;
  grid-template-columns: 365px 1fr;
  grid-template-rows: 1fr 55px;
  position: relative;
  overflow:hidden;
  background-color: white;
  box-shadow: var(--shadow-toggle);
}

#athletic-container img {
  width: 100%;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

#athletic-text-field {
  width: auto;
  margin: 0 10px 0 10px;
  text-wrap: wrap;
  position: relative;
  z-index: 1;
  padding: 0 10px 0 0;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

#athletic-text-field a{
  text-decoration: none;
}

#athletic-text-field h3 {
  color: #FFBB00;
  font-size: 1.2em;
  line-height: 1.2em;
  margin: 10px 0 0 0;
}

#athletic-text-field h3:hover {
  color: var(--secondary-text-color);
}

#article-data-btns {
  padding-left: 10px;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: 1fr 80px;
}

#article-data {
  grid-column: 1 / 2;
}

#article-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 2 / 3;
  padding-top: 7px;
}

#sport-title {
  color: #FFBB00;
  font-size: 1em;
  font-weight: bold;
  line-height: 0px;
  margin-bottom: 20px;
}

#article-date {
  color: black;
  font-size: .8em;
  line-height: 0;
}



/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**************************DEPARTMENTS*************************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#directory-field-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#directory-field-container h1 {
  color: #FFBB00;
}

#directory-container {
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  background-color: var(--level-two-background);
  box-shadow: var(--shadow-toggle);
}

#directory-text-field p {
  color: black;
  font-size: .99em;
  line-height: 1em;
}

#directory-text-field a:hover{
  color: #FFBB00;
}

#directory-text-field ul li::before {
  content: "> ";
}

#directory-buttons-container {
  display: flex;
  justify-content: space-evenly;
}

#directory-list-container-button-area {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: relative;
}

.directory-btn {
  text-wrap: wrap;
  font-size: .95em;
  font-weight: 500;
  color: var(--primary-text-color);
  width: 175px;
  height: 50px;
  padding: 15px;
  border-radius: 10px;
  border: 0;
  background-color: var(--button-background-color);
  vertical-align: top;
  /*By default the verical alignment of inline elements is baseline. With top - buttons will now be centered even if text wraps*/
  text-overflow: ellipsis;
  line-height: .9em;
}

.directory-btn.active {
  background-color: var(--primary-hover-color);
  color: var(--nav-text-color);
}

.directory-btn:hover {
  /* color: var(--button-background-color); */
  color: black;
  /* background-color: var(--primary-hover-color); */
  background-color: #FFBB00;
}

/** REUSABLE TOWSON NAVIGATION BUTTONS **/

.towson-btn {
  text-wrap: wrap;
  font-size: .95em;
  font-weight: 500;
  color: var(--primary-text-color);
  max-width: auto;
  min-width: 107px;
  max-height: auto;
  min-height: 40px;
  margin: 0 7px 0 7px;
  border-radius: 25px;
  border: 0;
  background-color: var(--button-background-color);
  vertical-align: top;
  text-overflow: ellipsis;
  line-height: .9em;
}

.towson-btn.active {
  background-color: var(--primary-hover-color);
  color: var(--nav-text-color);
}

.towson-btn:hover {
  border-radius: 25px;
  color: black;
  background-color: #FFBB00;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/******************************LIBRARY*************************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#library-field-container {
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#library-field-container h1 {
  color: #FFBB00;
}

#library-container {
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  background-color: white;
  box-shadow: var(--shadow-toggle);
}

#library-buttons-container {
  display: flex;
  /* flex-wrap: wrap;  //used for when we want the buttons to wrap the a new line, but i dont think we want that */
  justify-content: space-evenly;
}

#library-list-container-button-area {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}




/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/******************************Greek Life**********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/



#GreekLifeContentSpacer{
  padding: 10px;
}

#club-events-hr {
  width: 100%;
  max-width: 280px;
  min-width: 100px;
  height: 20px;
  opacity: .7;
}

#club-events-listings-area {
  width: 100%;
  /* grid-template-columns: 90px 1fr;
  grid-template-rows: 1fr 1fr; */
  color: var(--secondary-text-color);
  margin-bottom: 0 0 5px 0;
  /* border: 2px solid red; */
}

/* #club-events-stats-title {
  line-height: 0px;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

#club-events-listings-area h5 {
  font-size: .8em;
  line-height: 0;
  font-weight: normal;
  padding: 0;
  margin: 0 0 20px 0;

}

#club-events-listings-area h3 {
  color: var(--primary-text-color);
  font-size: 1.1em;
  line-height: 0px;
  margin-bottom: 16px;
}

#club-events-listings-area h3:hover {
  color: var(--primary-hover-color);
  cursor: pointer;
}

#club-events-listings-area p {
  font-size: .8em;
  line-height: 1.4em;
}

#club-events-date {
  font-weight: 700;
}

#club-events-description {
  width: 50px;
  font-size: 1em;
  text-wrap: wrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#club-events-description.show {
  white-space: break-spaces;
  overflow: unset;
}


/******* FORMS *******/
.create-event-modal-content {
  min-width: 400px;
  animation-name: create-post-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 40;
}

.ban-appeal-modal-content {
  width: auto;
  min-width: 400px;
  animation-name: create-post-pop;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 40;
}

#banned-members-container {
  column-gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**************************MESSAGING APPLICATION***************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

/* #messages-controls-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

#messages-controls-area hr {
  opacity: .5;
  width: 100%;
}

#messages-controls-area button {
  outline: none;
  border: none;
  padding: 0;
}

#messages-controls-area button:hover {
  color: var(--primary-hover-color);
} */

.message-area {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin-bottom: 7px;
}

.message-area-unseen {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: 1px solid rgba(255, 187, 0, 0.5);
  border-radius: 15px;
  margin-bottom: 5px;
}

.message-container {
  grid-template-columns: 35px 1fr 35px;
  margin-bottom: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 5px 10px;
}

.message-container:last-child {
  margin-bottom: 0;
}

.message-container img {
  grid-column: 1 / 2 ;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  margin: auto;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background-size: cover;
}

.message-container-fg-icon {
  grid-column: 1 / 2 ;
  /* border: 2px solid var(--primary-color); */
  /* border-radius: 50%; */
  margin: auto;
  /* width: 30px;
  height: 30px; */
  box-sizing: border-box;
  background-size: cover;
  /* border: 10px solid red; */
}

.message-text-area {
  width: 100%;
  grid-column: 2 / 3;
  grid-template-rows: 25px 1fr;
  box-sizing: border-box;
  padding-left: 5px;
}

.message-text-area h2 {
  color: var(--message-app-primary-text);
  grid-row: 1 / 2;
  font-size: .75em;
  line-height: 0;
  font-weight: bold;
  white-space: nowrap;
  box-sizing: border-box;
  width: inherit;
  padding-top: 2px;
}

.message-text-area h2:hover {
  color: var(--primary-hover-color);
  cursor: pointer;
}

#message-content {
  color: var(--message-app-secondary-text);
  width: inherit;
  grid-row: 2 / 3;
  font-size: .7em;
  line-height: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 5px 0px 10px 0;
  display: inline-block;
}

.message-details {
  grid-column: 3 / 4;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1px;
}

.message-details p {
  color: var(--message-app-secondary-text);
  line-height: 0;
  font-weight: 500;
  font-size: .7em;
  opacity: .7;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.message-counter-container {
  grid-column: 1 / 3;
  grid-row: 2/ 3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.message-counter {
  grid-column: 1 / 3;
  grid-row: 2/ 3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--primary-hover-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-counter p {
  font-weight: bold;
  font-size: .6em;
}

/* NEW FULL SCREEN MESSAGE APP */

#message-application-dashboard-container {

  width: 100vw;
  min-width: 400px;
  height: 85vh;
  margin-top: 70px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

#message-application-container {
  min-width: 400px;
  width: 95%;
  height: 100%;
  min-height: 400px;
  background-color: var(--message-app-container);
  z-index: 0;
  grid-template-columns: 100px 1fr;
  box-sizing: border-box;
  padding: 0px;
}

#message-application-toolbar-container {
  height: 100%;
  background-color: var(--message-app-container);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width:none;
}

#message-application-toolbar-container:hover {
  overflow-y: scroll;
}

#message-application-toolbar-btn-area {
  height: 100%;
  box-sizing: border-box;
  padding-top: 25px;
  overflow: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#toolbar-top, #toolbar-bottom {
  width: 70px;
}

.message-application-toolbar-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message-application-toolbar-academic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.message-application-toolbar-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#message-application-toolbar-container hr {
  width: 100%;
  opacity: .2;
  margin-top: 10px;
  margin-bottom: 30px;
}

#message-application-toolbar-container ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#message-application-toolbar-container ul li {
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: .55;
  margin-bottom: 2.5px;
}

#message-application-toolbar-container ul li:hover {
  cursor: pointer;
  opacity: 1;
}

#message-application-toolbar-container label {
  font-size: .65em;
  opacity: .6;
  color: var(--message-app-toolbar-labels);
  margin-bottom: 20px;
}

#message-application-chat-area-container {
  grid-column: 2 / end;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 10px 10px 10px 0;
  border-radius: 20px;
  gap: 10px;
}

#message-application-left-panel-container {
  padding: 10px 0px;
  min-width: 200px;
  max-width: 300px; 
  background-color: var(--message-app-panel-background);
  border-radius: 20px;
  box-sizing: border-box;
  grid-template-rows: 40px 1fr;
  gap: 10px;
}

/* #message-application-user-field-container {
  padding: 10px 0px;
  min-width: 200px;
  max-width: 300px; 
  background-color: var(--message-app-panel-background);
  border-radius: 20px;
  box-sizing: border-box;
  grid-template-rows: 40px 1fr;
  gap: 10px;
} */

#message-application-user-search-field {
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

#message-application-user-search-field input{
  color: var(--primary-color);
  font-size: .9em;
  height: 20px;
  width: 100%;
  background-color: var(--message-app-chat-bubble);
  border-radius: 15px;
  border: none;
  outline: none;
  padding: 10px;
}

#message-application-user-field {
  grid-row: 2 / 3;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  /* overflow: hidden; */
}


#message-application-chat-field-container {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  grid-template-rows: 1fr fit-content;
  background-color: var(--message-app-panel-background);
  overflow: hidden;
}

.message-application-chat-field {
  min-height: 100%;
  background-color: var(--message-app-panel-background);
  padding: 5px;
  box-sizing: border-box;
  grid-row: 1 / 2;
  overflow-y: hidden;
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  padding-right: 2px;
}

.message-application-chat-field:hover {
  overflow-y: scroll;
  padding-right: 2px;
  scroll-behavior: smooth;
}

.date-separator {
  width: 100%;
  color: var(--button-text-color);
  font-size: .8em;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: .6;
  margin: 25px 0 5px 0;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.date-separator-classroom {
  margin: 25px 0 25px 0 !important;
}

.date-separator::before,
.date-separator::after {
  content: '';
  flex: 1;
  border-bottom: .5px solid var(--button-text-color);
  opacity: .2;
}

.date-separator:not(:empty)::before {
  margin-right: .6em;
  margin-left: 10px;
}

.date-separator:not(:empty)::after {
  margin-left: .6em;
  margin-right: 10px;
}

.message-app-input-field {
  grid-row: 2 / 3;
  padding: 10px;
  display: flex;
  align-items: flex-end;
}

#message-app-input-container {
  width: 100%;
  min-height: 40px;
  max-height: fit-content;
  padding: 8px;
  background-color: var(--message-app-chat-bubble);
  border-radius: 10px;
  display: flex;
  align-items: center;
  grid-template-columns: fit-content 1fr fit-content;
  box-sizing: border-box;
  position: relative;
}

.message-app-input-icons {
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.message-app-input-icons ul {
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-app-input-icons ul li {
  width: 30px;
  display: flex;
  align-items: center;
  opacity: .7;
}

.message-app-input-icons ul li:hover {
  opacity: 1;
  cursor: pointer;
}

#message-app-input-container textarea {
  color: var(--message-app-secondary-text);
  font-size: 1em;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  display: flex;
  min-height: 20px;
  max-height: 100px;
  grid-column: 2 / 3;
  margin-right: 5px;
}

#message-app-send-btn-container {
  grid-column: 3 / 4;
}

.message-right-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
}

.message-left-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
}

.message-container-foreign-user {
  width: fit-content;
  height: fit-content;
  max-width: 93%;
  grid-template-columns: 50px 1fr;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 3px 5px;
}

.message-foreign-user-avatar-container {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  background-color: var(--message-app-chat-bubble);
  grid-column: 1 / 2;
  height: 59px;
  padding: 0px;
}

.message-user-avatar {
  width: 60px;
  border-radius: 50%;
  padding: 5px;
  box-sizing: border-box;
  background-color: var(--message-app-chat-bubble);
}

.message-foreign-user-avatar-blank {
  width: 100%;
  height: 100%;
  margin-right: 60px;
  background-color: var(--message-app-panel-background);
}

.message-current-user-avatar-blank {
  width: 100%;
  height: 100%;
  margin-right: 60px;
  background-color: var(--message-app-panel-background);
}

.message-content-foreign-user {
  width: 100%;
  grid-column: 2 / 3;
  grid-template-rows: 30px 1fr;
  background-color: var(--message-app-chat-bubble);
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
}

.message-content-foreign-user h3 {
  color: var(--message-app-primary-text);
  font-size: .8em;
  margin: 0;
}

.message-content-foreign-user p, .message-content-current-user p {
  color: var(--message-app-secondary-text);
  font-size: .8em;
  height: fit-content;
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
  text-wrap: wrap;
}

.message-container-current-user {
  width: fit-content;
  height: fit-content;
  max-width: 93%;
  grid-template-columns: 1fr 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 3px 5px;
  box-sizing: border-box;
}

.message-current-user-avatar-container {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: var(--message-app-chat-bubble);
  grid-column: 2 / 3;
  height: 59px;
}

.message-content-current-user {
  width: 100%;
  height: fit-content;
  grid-column: 1 / 3;
  background-color: var(--message-app-chat-bubble);
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
}

/* CHATBOT */

#message-app-chatbot-past-queries-field-container {
  padding: 10px;
  min-width: 200px;
  max-width:300px;
  background-color: var(--message-app-panel-background);
  border-radius: 20px;
  box-sizing: border-box;
}

#message-app-chatbot-past-queries-field-container p {
  color: var(--message-app-primary-text);
  font-size: .9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#message-app-chatbot-past-queries-field-container p:hover {
  cursor: pointer;
}

#message-app-chatbot-query-field {
  overflow: scroll;
  scroll-behavior: smooth;
}

.past-query {
  height: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.past-query:hover {
  background-color: var(--primary-hover-color);
}

/* CLASSROOM */

#message-app-classroom-left-panel {
  min-width: 200px;
  max-width: 250px;
  background-color: var(--message-app-panel-background);
  color: var(--message-app-primary-text);
  padding: 10px 15px;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}

#message-app-classroom-left-panel:hover {
  overflow-y: scroll;
}

#message-app-classroom-left-panel h1 {
  font-size: 1.4em;
  line-height: 0;
  margin-bottom: 30px;
}

#message-app-course-code-collapsible-container button{
  color: var(--message-app-primary-text);
  width: 100%;
  text-align: left;
  border: none;
  padding: 2px 0;
}

#message-app-course-code-collapsible-container button:hover{
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
}

#message-app-course-code-collapsible-container hr {
  width: 100%;
  opacity: .2;
  margin-bottom: 20px;
}

#message-app-course-code-btn {
  width: 100%;
  color: var(--primary-text-color);
  margin-bottom: 10px;
  border: none;
  font-weight: bold;
}

.channel-btn{
  width: 100%;
  font-size: .9em;
  font-weight: normal;
  border: none;
  margin-left: 20px;
  margin-bottom: 3px;
}

.channel-btn:last-child {
  margin-bottom: 5px;
}

#message-app-classroom-chat-field-container {
  width: 100%;
  padding: 10px;
  background-color: var(--message-app-panel-background);
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}

#message-app-classroom-content {
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto fit-content;
}

#message-app-classroom-expanded-message {
  min-width: 350px;
  max-width: 450px;
  height: 100%;
  margin-left: 10px;
  background-color: var(--message-app-panel-background);
  padding: 10px;
  box-sizing: border-box;
  animation-name: expand-slide;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  position: relative;
}

#message-app-classroom-expanded-message:hover {
  overflow-y: scroll;
}

@keyframes expand-slide {
  0% { 
    transform: translateX(100%);
  };
}

#message-app-classroom-field {
  width: 100%;
  min-height: 100%;
  background-color: var(--message-app-panel-background);
  grid-row: 1 / 2;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  border-top-right-radius: 15px;
  animation-name: expand-slide;
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}

#message-app-classroom-field:hover {
  overflow-y: scroll;
}

#message-app-classroom-message-field-file-preview-area {
  overflow: hidden;
  display: flex;
  grid-row: 2 / 3;
  background-color: var(--message-app-panel-background);
}

#message-app-file-preview-container {
  width: 120px;
  max-height: 150px;
  margin: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--message-app-chat-bubble);
  padding: 5px;
  box-sizing: border-box;
}

#message-app-file-preview-container img {
  width: 100px;
}

.add-files-pop-up-icons {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
  gap: 5px;
}

.add-files-pop-up-icons:hover {
  cursor: pointer;
  background-color: var(--message-app-panel-background);
}

#message-app-add-files-pop-up {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  width: fit-content;
  height: fit-content;
  border: var(--nav-text-color);
  padding: 10px;
  overflow: hidden;
  background-color: var(--message-app-pop-up);
  box-sizing: border-box;
  z-index: 15;
}

#message-app-add-files-pop-up ul{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

#message-app-add-files-pop-up ul li {
  opacity: .8;
}

#message-app-add-files-pop-up ul li label {
  font-size: .8em;
  color: var(--message-app-toolbar-labels);
}


#message-app-classroom-message-field {
  height: 100%;
  background: var(--message-app-panel-background);
  grid-row: 3 / end;
  padding: 10px;
  box-sizing: border-box;
  border-bottom-right-radius: 20px;
}

#message-app-channel-message-container {
  width: 100%;
  min-width: 350px;
  max-height:max-content;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  background-color: var(--message-app-chat-bubble);
  border-radius: 15px;
  grid-template-rows: auto 1fr;
  box-sizing: border-box;
}

#message-app-channel-message-title-container {
  grid-row: 1 / 2;
  grid-template-columns: 45px 1fr;
  grid-template-rows: 45px 1fr;
  padding: 0;
  margin-bottom: 10px;
}

.channel-user-avatar {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  top: 10px;
  grid-column: 1 / 2;
}

#message-app-channel-message-title-heading {
  margin-left: 7px;
  grid-column: 2 / 3;
  grid-template-rows: 1fr 1fr;

}

#message-app-channel-message-title-container h3 {
  font-size: 1em;
  position: relative;
  top: 5px;
  color: var(--primary-text-color);
  grid-row: 1 / 2;
  padding: 0;
  margin: 0;
  z-index: 0;
}

#message-app-channel-message-title-container p {
  position: relative;
  top: 5px;
  font-size: .7em;
  color: var(--secondary-text-color);
  grid-row: 2 / 3;
  margin: 0;
}

#message-app-channel-message {
  width: 100%;
  font-size: 0.8em;
  max-height:fit-content;
  background-color: transparent;
  margin: 0;
  color: var(--primary-text-color);
  grid-row: 3 / 4;
}

#message-app-channel-message p{
  color: var(--message-app-secondary-text);
  font-size: 1em;
  height: fit-content;
  white-space: pre-wrap;
  text-wrap: wrap;
}



.course-name-separator, .channel-name-separator {
  width: 100%;
  color: var(--button-text-color);
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .6;
  margin: 0px 0 5px 0;
  margin-left: auto;
  margin-right: auto;
}


/* COMMON */

.activeChat {
  width: 100%;
  text-wrap: wrap;
  background-color: var(--message-app-chat-bubble);
}

.active-chat-room {
  opacity: 1 !important;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**************************tutoring****************************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#tutoring-info-container {
  width: 100%;
  height: 100%;
  /* background-color: var(--page-background); */
}

#tutor-bio-container {
  padding: 10px;
  border-bottom: 2px solid var(--primary-color);
}

#tutor-name {
  padding-left: 10px;
  display: inline-block;
}

#tutor-profile-img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  top: 7px;
  display: inline-block;
}

#tutor-update-field{
  background-color: var(--level-two-background);
}

#tutoring-subject-title{
  color: var(--primary-text-color);
  padding-bottom: 15px;
}

#tutoring-back-button{
  position: absolute !important;
  top: 0;
  left: 0;
}

/* #cards-field-container  */
#tutoring-field-container{
  min-width: var(--w-minimum);
  max-width: var(--w-maximum);
  height: auto;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  background-color: var(--level-two-background);
  z-index: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

#subject-updates-info-slide{
  background-color: var(--level-three-background);
  /* background-color: var(--container-background-color); */
  width: 90%;
  height: auto;
  overflow: hidden;
  display: none;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding: 10px;
  margin-bottom: 20px;
}

#tutoring-schedule-anchor a{
  color: rgb(0, 98, 255);
}

#tutoring-schedule-anchor a:hover{
  color: #FFBB00;
}


.c2b64-container {
  width: fit-content;
  max-height: 100x;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c2b64-image-preview-container {
  width: 150px;
  max-height: 150px;
  margin: 5px 0 5px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--message-app-chat-bubble);
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
}

.c2b64-image-preview-container img {
  width: 100px;
}

.c2b64-input-btn {
  display: flex;
  align-items: center;
}

.c2b64simple-input-btn-area {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
}

.c2b64simple-add-img-btn {
  width: 25px;
  height: 25px;
  color: var(--primary-color);
  border-radius: 50%;
  background-color: var(--message-app-chat-bubble);
  padding: 5px;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  align-items: center;
  z-index: 100;
}

.c2b64simple-add-img-btn:hover {
  background-color: var(--primary-hover-color);

}


/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/******************************TESTIMONIALS********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/


#testimonial-container {
  width: 100%;
  padding: 10px 20px;
  /* background-color: white; */
  grid-template-columns: 150px 65px 1fr;
  display: flex;
  align-items: center;
  gap: 10px;
}

#testimonial-container img {
  width: 100%;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  border-radius: 50%;
  grid-column: 1 / 2;
  border: 6px solid var(--level-two-background);
  box-sizing: border-box;
  object-fit: cover;
}

.testimonial-student {
  font-size: 1.1em;
  color: var(--secondary-text-color);
}

.quote {
  font-family: "Playfair Display", serif !important;
  color: var(--primary-color);
  font-size: 1.5em;
  font-weight: normal;
}


.quote-mark {
  color: var(--towson-gold);
  opacity: .3;
  font-family: "Passion One", sans-serif;
  font-weight: 200;
  font-size: 6em;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  align-self: flex-start !important;
  margin: 10px 0 0 20px;
}

.passion-one-bold {
  font-family: "Passion One", sans-serif;
  font-weight: 700;
  font-style: normal;
}



/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/**********************************BUTTONS*********************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

/* SIGN UP BUTTON */
#sign-up-btn-container {
  width: fit-content;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  grid-template-columns: 50px 1fr;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
}

#sign-up-btn-emblem {
  background-color: var(--primary-color);
  height: 45px;
  margin: 0;
  border-radius: 50%;
  padding: 0 3px 0 2px;
}

#sign-up-btn {
  font-weight: 700;
  color: var(--primary-color);
  font-size: .9em;
  width: 100%;
  height: 45px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 5px;
  border-radius: 50px;
}

#sign-up-btn-container:hover {
  color: var(--towson-gold) !important;
  opacity: .8;
  cursor: pointer;
  border: 2px solid var(--towson-gold);
}

/* GENERIC BUTTON*/
.default-btn {
  min-width: fit-content;
  max-width: 180px;
  height: fit-content;
  background-color: var(--button-background-color);
  padding: 5px 15px;
  border-radius: 25px;
}

.hover-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

 /* Study group container and banner */
/* #message-application-study-group-field-container {
  width: 100%;
  padding: 10px 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--message-app-panel-background);
  overflow: hidden;
} */

/* #message-application-study-group-field-container {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  grid-template-rows: 1fr 1fr fit-content;
  background-color: var(--message-app-panel-background);
  overflow: hidden;
} */

/* #message-application-study-group-field-container {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  background-color: var(--message-app-panel-background);
  overflow: hidden;
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 10px;
} */

#message-application-study-field-container {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  grid-template-rows: 1fr fit-content;
  background-color: var(--message-app-panel-background);
  overflow: hidden;
}

.message-application-study-field {
  min-height: 100%;
  background-color: var(--message-app-panel-background);
  padding: 5px;
  box-sizing: border-box;
  grid-row: 1 / 2;
}

.message-application-study-group-field {
  width: 100%;
  flex-grow: 1;
  background-color: var(--message-app-panel-background);
  padding: 5px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.study-group-banner {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: space-between;
  text-align: center;
  color: var(--banner-text-color);
  font-size: 20px;
  border-radius: 15px;
  background-color: lightgray;
  margin: 5px;
  border: 1px solid darkgray;
  position: sticky;
  top: 0;
  z-index: 1;
}

.study-group-banner h3 {
  color: black;
}

.study-group-banner img {
  cursor: pointer;
  height: 50px;
  width: 50px;
}

#message-app-study-members {
  min-width:250px;
  max-width: 100%;
  height: 100%;
  background-color: var(--message-app-panel-background);
  padding: 10px;
  box-sizing: border-box;
  animation-name: expand-slide;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}

#message-app-study-members:hover {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.date-separator-study {
  width: 100%;
  /* color: var(--button-text-color); */
  color: black;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: .6;
  margin: 25px 0 5px 0;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.date-separator-study::before,
.date-separator-study::after {
  content: '';
  flex: 1;
  border-bottom: .5px solid var(--button-text-color);
  opacity: .2;
}

.date-separator-study:not(:empty)::before {
  margin-right: .6em;
  margin-left: 10px;
}

.date-separator-study:not(:empty)::after {
  margin-left: .6em;
  margin-right: 10px;
}

#study-group-members-listings-area {
  width: fit-content;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr auto auto;
  color: var(--secondary-text-color);
  padding-bottom: 10px;
  /* padding: 20px; */
}

#study-group-members-listings-area  img {
  width: 50px;
  height: 50px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  margin: 5px;
  object-fit: cover;
}

#study-group-members-stats-title {
  font-size: .8em;
  line-height: 0px;
  margin-left: 5px;
  grid-column: 2 / end;
  grid-row: 1 / 2;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#study-group-members-stats-title p{
  line-height: 0px;
  font-size: .9em;
  margin-top: 20px;
  grid-column: 2 / end;
  grid-row: 2 / 3;
}

#manage-members-btns {
  grid-column: 1 / end;
  grid-row: 3 / end;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* private chat user info */
#message-app-private-chat-user-info {
  /* width: 22%; */
  min-width:22%;
  max-width: 22%;
  height: 100%;
  background-color: var(--message-app-panel-background);
  padding: 10px;
  box-sizing: border-box;
  animation-name: expand-slide;
  animation-duration: .7s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.private-chat-user-info-img-area {
  width: 100%;
  min-height: fit-content;
  height: 190px;
  background-color: var(--button-background-color);
  position: relative;
  padding: 0;
}

.private-chat-user-info-img-area img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
  object-fit: cover;
}

.private-chat-user-info-profile-img-area {
  width: 100%;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  top: calc(95% / 2);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.private-chat-user-info-profile-img-area img {
  width: 130px;
  height: 130px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 50%;
  border: 4px solid var(--level-two-background);
  box-sizing: border-box;
}

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/*******************************HELPFUL TOOLS******************************/
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

#helpful-tools-field {
  background-color: var(--level-two-background);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: center;
}

#helpful-tools-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 20px 0;
}

.helpful-tool-container {
  width: 360px;
  height: 115px;
  background-color: var(--level-three-background);
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 60px 15px 15px 60px;
}

.helpful-tool-container:hover {
  cursor: pointer;
  opacity: .8;
}

.helpful-tool-text-area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.helpful-tool-container img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  box-sizing: border-box;
  object-fit: cover;
  border: 5px solid var(--level-two-background);
  box-sizing: border-box;
}

.helpful-tool-text-area h3 {
  color: var(--primary-text-color);
  font-size: 1.2em;
  margin: 0;
}

.helpful-tool-text-area p {
  color: var(--secondary-text-color);
  font-size: .8em;
  margin: 0;
}

.helpful-tool-recommendations-list {
  color: var(--secondary-text-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  font-size: .7em;
  opacity: .8;
  padding-top: 5px;
}

.info-card-container {
  min-width: 350px;
  max-width: 400px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--level-two-background);
  border-radius: 15px;
  overflow: hidden;
  box-sizing: border-box;
  gap: 10px;
  padding: 20px;
  box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.5), 0 0 5px 0 rgba(0, 0, 0, .2);
}

.info-card-container img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  align-self: center;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3), 0 0 5px 0 rgba(0, 0, 0, .2);
  margin-top: 5px;
}

.info-card-container h2 {
  color: var(--primary-text-color);
  font-size: 1.5em;
  margin: 0;
}

.info-card-container p {
  color: var(--secondary-text-color);
  font-size: .9em;
  margin: 5px 0px;
}

.paragraph-lead {
  color: var(--primary-text-color);
}

/**************************************************************************/
/* THESE ARE USED SHOULD WE DECIDE TO DISPLAY THE LITTLE AVATARS OF THE PEOPLE WHO RECOMMEND */
.recommendation-container {
  width: 30px;
  height: 30px;
  background-color: var(--message-app-chat-bubble);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  box-sizing: border-box;
}

.recommendation-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
/**************************************************************************/

.mutual-container {
  width: auto;
  height: auto;
  margin-left: 10px;
}

.mutual-listing{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin: 5px;
  /* margin-left: 30px; */
  /* border: 1px solid blue; */
}

.mutual-listing img{
  width: 10%;
  height: 10%;
  border-radius: 50%;
  margin-right: 10px;
}

.mutual-listing-user-info{
  display: flex;
  /* flex-direction: column; */
  /* border: 1px solid pink; */
}

.friend-group-modal-container {
  min-width: var(--w-main-minimum);
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  background-color: var(--level-two-background);
  margin: 10px auto;
  width: 30%;
}

#friend-group-modal-header {
  grid-row: 1 / 2;
}

#friend-group-modal-body {
  grid-row: 2 / 3;
  min-width: 90%;
  max-width: 860px;
  max-height: 76vh;
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 25px;
}

#friend-group-modal-rc {
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  height: 100%;
  overflow: scroll;
}

.friend-group-button-area {
  padding: 25px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  width: auto;
  margin: 0;
}

.friend-group-button-area button {
  min-width: 125px;
  max-width: 150px;
  font-size: .9em;
  font-weight: bold;
  border: none;
  height: 50px;
  border-radius: 5px;
}

.friend-group-button-area button:hover {
  color: var(--primary-text-color);
  background-color: var(--primary-hover-color);
}

/**************************************************************************/
.group-listing-container {
  display: flex;
  align-items: center;
}

.action-button-container {
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}





/* FOR REUSABLE POST HEADER COMPONENT THAT INCLUDES AN AVATAR WITH TWO LAYERS OF TEXT TO THE RIGHT OF IT */

.new-post-form-header {
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--level-three-background);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.title-container {
  place-self: center;
  width: 100%;
  border-radius: 25px;
  border: 0px;
  height: 60px;
  grid-row: 1 / 2;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr 1fr;
  z-index: 1;
}

.title-container h3 {
  font-size: 1.1em;
  position: relative;
  top: 10px;
  color: var(--primary-text-color);
  grid-column: 2 / end;
  grid-row: 1 / 2;
  padding: 0;
  margin: 0;
  z-index: 0;
}

.title-container p {
  position: relative;
  top: 7px;
  font-size: small;
  color: var(--secondary-text-color);
  grid-column: 2 / end;
  grid-row: 2 / end;
  padding: 0;
  margin: 0;
}

.title-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  top: 7px;
  grid-row: 1 / end;
  grid-column: 1 / 2;
}


/* BUTTONS */
.action-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.action-btn {
  width: fit-content;
  border: none;
  font-size: .85em;
  font-weight: 500;
  color: var(--action-btn-text);
}

.ocean-header-btns {
  border-radius: 10px;
  border: none;
  background-color: var(--button-background-color);
  margin: 10px 10px 10px 0px;
  padding: 5px 10px;
  font-size: .85em;
  font-weight: 600;
}

.ocean-header-btns:hover {
  cursor: pointer;
  background-color: var(--primary-hover-color);
}


.icon-with-label {
  width: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.icon-with-label p {
  font-size: .65em;
  color: var(--secondary-text-color);
  line-height: 0;
  font-weight: 600;
}

#general-button-area-container {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.general-btn {
  text-wrap: wrap;
  font-size: .95em;
  font-weight: 500;
  color: var(--primary-text-color);
  width: 175px;
  height: 50px;
  padding: 15px;
  border-radius: 10px;
  border: 0;
  background-color: var(--button-background-color);
  vertical-align: top;
  text-overflow: ellipsis;
  line-height: .9em;
}

.general-btn.active {
  background-color: var(--primary-hover-color);
  color: var(--nav-text-color);
}

.general-btn:hover {
  cursor: pointer;
  /* color: var(--button-background-color); */
  color: black;
  /* background-color: var(--primary-hover-color); */
  background-color: #FFBB00;
}