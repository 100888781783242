@import "oceansStyles.css";

.mobile-main-container {
    width: 95vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    align-self: center;
    justify-self: center;
}

.welcome-mobile-container {
    max-width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--level-two-background);
    box-sizing: border-box;
    padding: 20px;
}

.welcome-mobile-container h2 {
    font-size: 1.5em;
    color: var(--primary-text-color);
    text-align: center;
    margin: 10px 0 20px 0;
}

.welcome-mobile-container-text-area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--level-two-background);
    box-sizing: border-box;
    margin-bottom: 20px;
    gap: 10px;
}

.welcome-mobile-container p {
    font-size: 1.1em;
    color: var(--primary-text-color);
    text-align: center;
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .mobile-main-container {
        width: 95vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        align-self: center;
        justify-self: center;
    }
    
    .welcome-mobile-container {
        max-width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: var(--level-two-background);
        box-sizing: border-box;
        padding: 20px;
    }
    
    .welcome-mobile-container h2 {
        font-size: 1.5em;
        color: var(--primary-text-color);
        text-align: center;
        margin: 10px 0 20px 0;
    }
    
    .welcome-mobile-container-text-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: var(--level-two-background);
        box-sizing: border-box;
        margin-bottom: 20px;
        gap: 10px;
    }
    
    .welcome-mobile-container p {
        font-size: 1.1em;
        color: var(--primary-text-color);
        text-align: center;
        margin: 0;
    }
}

.highlight {
    color: var(--towson-gold);
}

.welcome-mobile-container h1 {
    font-size: 1.5em;
    color: var(--primary-text-color);
    text-align: center;
    margin: 0;
}

.logo-area {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.welcome-container {
    min-width: 400px;
    max-width: 830px;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: var(--level-two-background);
    box-sizing: border-box;
    margin-bottom: 10px;
}

.welcome-container h1 {
    font-size: 2em;
    color: var(--primary-text-color);
    text-align: left;
    margin: 0;
}

.welcome-container h2 {
    font-size: 2.6em;
    color: var(--primary-text-color);
    text-align: left;
    margin: 0;
}

.welcome-container span {
    color: var(--towson-gold);
}

.welcome-container h3 {
    font-size: 1.7em;
    color: var(--primary-text-color);
}

.welcome-container ul {
    padding: 0;
    color: var(--primary-text-color);
    margin-bottom: 0;
}

.welcome-container p {
    color: var(--primary-text-color);
    line-height: 1.6;
    text-align: left;
}

.important-note {
    border-radius: 5px;
    color: var(--secondary-text-color);
    text-align: left;
}

.feedback {
    margin-top: 40px;
    border-radius: 5px;
    color: var(--primary-text-color);
    text-align: left;
}

.regards {
    text-align: left;
}

.regards p {
    margin: 0;
}

.options-cards-area {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.option-card-container {
    width: 250px;
    height: 250px;
    padding: 20px 10px;
    box-sizing: border-box;
    grid-template-rows: 100px 50px 1fr;
    background-color: var(--primary-color);
    box-shadow: 0 0 17px 0px rgba(0, 0, 0, 0.3), 0 0 5px 0px rgba(0, 0, 0, 0.5);
}

.option-card-container img {
    grid-row: 1 / 2;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-sizing: border-box;
    justify-self: center;
    align-self: center;
    border: none;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3), 0 0 5px 0px rgba(0, 0, 0, 0.4);
}

.option-card-container h2 {
    grid-row: 2 / 3;
    font-size: 1.2em;
    color: #ddd;
    text-align: center;
    margin: 0;
    padding-top: 10px;
}

.option-card-container p {
    grid-row: 3 / 4;
    font-size: .8em;
    color: #ccc;
    text-align: center;
    margin: 0;
    align-content: center;
}

