@import "oceansStyles.css";
@import "developerWelcome.css";

.content-placeholder-container {
    background-color: var(--level-two-background);
    overflow: hidden;
}

.header-img {
    width: 100%;
    height: 300px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.4);
}

.content-placeholder-text-area {
    padding: 20px 30px;
}

.content-placeholder-text-area h2 {
    font-size: 2.5em;
    margin: 0 0 10px 0;
}

.content-placeholder-text-area p {
    color: var(--primary-text-color);
    font-size: 1em;
}